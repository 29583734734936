import axios from 'axios';
import { JAVA_API_COMPANY } from './ApiEndPoints';
import { GET_COMPANIES, SET_CURRENT_USER } from './types';
import jwt_decode from 'jwt-decode';
import { setHeaderToken } from '../security-config/LoginActions';

export const createNewCompany = (payload, callBack) => async (dispatch) => {
  try {
    let config = {
      headers: {
        Authorization: 'Bearer ' + localStorage.getItem('tempOrmToken'),
      },
    };
    const res = await axios.post(JAVA_API_COMPANY, payload, config);
    if (res.data.code === 'UASI0011') {
      const accessToken = res.data.data.access_token;
      // const refreshToken = res.data.data.refresh_token;

      const decoded = jwt_decode(accessToken);
      localStorage.setItem('ormToken', accessToken);
      // localStorage.setItem('ormRToken', refreshToken);
      localStorage.removeItem('tempOrmToken');
      setHeaderToken(accessToken);
      dispatch({
        type: SET_CURRENT_USER,
        payload: decoded,
      });
    }
    callBack(res.data);
  } catch {}
};

export const switchCompany = (code, callBack) => async (dispatch) => {
  const payload = {
    companyCode: code,
    companyId: '',
  };
  try {
    let config = {
      headers: {
        Authorization: 'Bearer ' + localStorage.getItem('tempOrmToken'),
      },
    };
    const res = await axios.put(JAVA_API_COMPANY, payload, config);
    if (res.data.code !== 'UASI0011') {
      callBack(res.data);
    } else {
      const accessToken = res.data.data.access_token;
      // const refreshToken = res.data.data.refresh_token;

      const decoded = jwt_decode(accessToken);
      localStorage.setItem('ormToken', accessToken);
      // localStorage.setItem('ormRToken', refreshToken);
      localStorage.removeItem('tempOrmToken');
      setHeaderToken(accessToken);
      dispatch({
        type: SET_CURRENT_USER,
        payload: decoded,
      });

      callBack(res.data);
    }
  } catch {}
};

export const getCompanyList = () => async (dispatch) => {
  let config = {
    headers: {
      Authorization: 'Bearer ' + localStorage.getItem('tempOrmToken'),
    },
  };
  const res = await axios.get(JAVA_API_COMPANY + '?page=0&zise=100', config);
  if (res.data.code === 'UASI0000') {
    dispatch({
      type: GET_COMPANIES,
      payload: res.data.data.company,
    });
  }
};
