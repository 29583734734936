import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { getApplications } from '../../actions/PersonRoleActions';
import { useDispatch } from 'react-redux';
import Grid from '@material-ui/core/Grid';
import { Card, CardActionArea, CardActions, CardContent, CardMedia, Paper } from '@material-ui/core';
import CommonUtil from '../../components/Util/CommonUtils';
import { Button } from 'antd';
import { Route, useHistory } from 'react-router';
import { REACT_URI_APPLICATIONS_PERSON } from '../../actions/EndPoints';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
}));

export default function Applications() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [membership, setMembership] = useState([]);
  let history = useHistory();

  useEffect((props) => {
    dispatch(
      getApplications((data) => {
        processData(data);
      })
    );
  }, []);

  function processData(data) {
    setMembership(data.membership);
  }

  const handleClick = (event, data) => {
    history.push({ pathname: REACT_URI_APPLICATIONS_PERSON + '/' + data, state: { action: 'REACT_CHECKLIST_PAGE', data: data } });
  };

  return (
    <div className={classes.root}>
      <Grid container spacing={1}>
        <Grid container item xs={12} spacing={3}>
          {membership.map((member) => (
            <Grid item xs={4}>
              <Card className={classes.root} onClick={(e) => handleClick(e, member.applicationId)}>
                <CardActionArea>
                  <CardContent>
                    <Typography gutterBottom variant='h5' component='h2'>
                      {CommonUtil.sentenceCase(member.applicationName)}
                    </Typography>
                  </CardContent>
                </CardActionArea>
              </Card>
            </Grid>
          ))}
        </Grid>
      </Grid>
    </div>
  );
}
