import { DASHBOARD_DATA } from '../actions/types';

const initialState = {
  dashBoardData: [],
};

export default function(state = initialState, action) {
  switch (action.type) {
    case DASHBOARD_DATA:
      return {
        ...state,
        dashBoardData: action.payload,
      };

    default:
      return state;
  }
}
