import { ZONE_LIST } from '../actions/types';

const initialState = {
  zoneList: [],
};

export default function(state = initialState, action) {
  switch (action.type) {
    case ZONE_LIST:
      return {
        ...state,
        zoneList: action.payload,
      };

    default:
      return state;
  }
}
