import React from 'react';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import { Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, makeStyles } from '@material-ui/core';

import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import biozlink from '../assets/images/onboard/access-cloud.png';
import cloudAccess from '../assets/images/onboard/cloudaccess.png';
import PersonIcon from '@material-ui/icons/Person';
import { toast } from 'react-toastify';
import CommonUtil from '../components/Util/CommonUtils';
import { forgotPassword } from '../actions/UserApiCalls';
import { REACT_URI_LANDING } from '../actions/EndPoints';
import './custom.css';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  form: {
    width: '100%',
    marginTop: theme.spacing(3),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
    backgroundColor: '#1090E5',
    borderRadius: '0.2rem',
    padding: '10px',
    '&:hover': {
      background: '#006fb9',
    },
  },
  container: {
    height: '100%',
    width: '100%',
    justifyContent: 'center',
    alignItems: 'center',
    display: 'flex',
  },
}));

export default function ForgotPassword(props) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  let history = useHistory();
  const classes = useStyles();

  const [state, setState] = React.useState({
    userName: '',
  });

  const [error, setError] = React.useState({
    userName: false,
  });

  const handleSubmit = (event) => {
    event.preventDefault();
    if (isFormValid(state)) {
      let obj = {};
      if (CommonUtil.isValidEmail(state.userName)) obj.email = state.userName;
      else obj.phone = state.userName;
      dispatch(
        forgotPassword(obj, (data) => {
          handleError(data);
        })
      );
    }
  };

  const handleError = (data) => {
    switch (data.code) {
      case 'UASE0003':
        setError({ userName: true });
        break;
      case 'UASI0004':
        history.push(REACT_URI_LANDING);
        break;
      case 'UASI0018':
        history.push(REACT_URI_LANDING);
        break;
      default:
        break;
    }
    if (data.code == 'UASI0004' || data.code == 'UASI0018') {
      toast.success(data.message);
    } else {
      toast.error(data.message);
    }
  };

  const isFormValid = (event) => {
    if (CommonUtil.isEmptyString(state.userName)) {
      setError({ userName: true });
      toast.error(t('COMMON053'));
      return false;
    }
    if (CommonUtil.isValidEmail(state.userName)) {
      return true;
    } else if (CommonUtil.isValidMobile(state.userName)) {
      return true;
    } else {
      return false;
    }
  };

  const handleChange = (event) => {
    const name = event.target.name;
    setState({
      ...state,
      [name]: event.target.value,
    });

    setError({
      [name]: false,
    });
  };

  return (
    <Fragment>
      <div className='background-image'>
        <Container component='main' maxWidth='md' className={classes.container}>
          <Grid container className='main_conatiner'>
            <Grid item sm={4} xs={12} className='paper_left'>
              <img src={biozlink} alt='logo' style={{ width: '250px', paddingTop: '50px' }} />
              <img src={cloudAccess} alt='logo' className='left_img' />
            </Grid>
            <Grid
              item
              sm={8}
              xs={12}
              className='paper_right'
              style={{
                justifyContent: 'center',
                alignItems: 'center',
                textAlign: 'center',
              }}
            >
              <Grid container spacing={5} alignItems='center'>
                <Grid item></Grid>
                <Grid item xs>
                  <Typography className='signin_title' variant='h5' color='textPrimary' align='center'>
                    {t('COMMON054')}
                  </Typography>
                </Grid>
              </Grid>
              <form className={classes.form} noValidate onSubmit={handleSubmit} autoComplete='off'>
                <Grid container alignItems='center'>
                  <Grid item>
                    <PersonIcon className='login_icons' />
                  </Grid>
                  <Grid item xs>
                    <TextField
                      error={error.userName}
                      className='login_input'
                      size='small'
                      variant='outlined'
                      margin='normal'
                      required
                      fullWidth
                      id='userName'
                      label={t('COMMON046')}
                      autoComplete='userName'
                      autoFocus
                      name='userName'
                      type='text'
                      value={state.userName}
                      onChange={handleChange}
                    />
                  </Grid>
                </Grid>
                <Grid container spacing={5} alignItems='center'>
                  <Grid item></Grid>
                  <Grid item xs>
                    <Button type='submit' fullWidth variant='contained' color='primary' variant='contained' className={classes.submit}>
                      {t('COMMON007')}
                    </Button>
                  </Grid>
                </Grid>
                <Grid container alignItems='center'>
                  <Grid item xs></Grid>
                  <Grid item>
                    <Button onClick={history.goBack} style={{ lineHeight: '20px', color: '#1090E5', fontSize: '13px' }}>
                      {t('COMMON040')}
                    </Button>
                  </Grid>
                </Grid>
              </form>
            </Grid>
          </Grid>
        </Container>
      </div>
    </Fragment>
  );
}
