import axios from 'axios';
import { JAVA_API_MEMBERSHIP, JAVA_API_PERSON } from './ApiEndPoints';

export const getApplications = (callBack) => async (dispatch) => {
  try {
    const res = await axios.get(JAVA_API_MEMBERSHIP);
    callBack(res.data.data);
  } catch {}
};

export const getMemberList = (applicationId, page, rowsPerPage, callBack) => async (dispatch) => {
  try {
    const res = await axios.get(JAVA_API_MEMBERSHIP + '/' + applicationId);
    callBack(res.data.data);
  } catch {}
};

export const getAllRoles = (applicationId, callBack) => async (dispatch) => {
  try {
    const res = await axios.get(JAVA_API_MEMBERSHIP + '/' + applicationId + '/role');
    callBack(res.data.data);
  } catch {}
};

export const perosnWithUser = (firstName, page, size, callBack) => async (dispatch) => {
  try {
    let query = '&withUser=true';
    if (firstName !== '') {
      query += '&firstName=' + firstName;
    }

    const res = await axios.get(JAVA_API_PERSON + '/' + 'search' + '?page=' + page + '&size=' + size + query);
    if (res.status === 200 && res.data.code === 'OMSI0000') {
      callBack(res.data);
    }
  } catch {}
};

export const addMembership = (payload, callBack) => async (dispatch) => {
  try {
    const res = await axios.post(JAVA_API_MEMBERSHIP, payload);
    if (res.status === 200) {
      callBack(res.data);
    }
  } catch {}
};

export const updateMembership = (payload, callBack) => async (dispatch) => {
  try {
    const res = await axios.put(JAVA_API_MEMBERSHIP, payload);
    if (res.status === 200) {
      callBack(res.data);
    }
  } catch {}
};

export const deleteMembership = (applicationId, userIds, callBack) => async (dispatch) => {
  try {
    const query = '?applicationId=' + applicationId + '&userId=' + userIds;
    const res = await axios.delete(JAVA_API_MEMBERSHIP + query);
    if (res.status === 200) {
      callBack(res.data);
    }
  } catch {}
};
