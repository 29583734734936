import React, { Fragment } from 'react';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';

class Dashboard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {}

  render() {
    return (
      <Fragment>
        <div>
          <Grid container spacing={5}>
            <Grid item xs={7}>
              <Paper className='dashboard-paper-left'></Paper>
            </Grid>
            <Grid item xs={5}>
              <Paper className='dashboard-paper-left'></Paper>
            </Grid>
            <Grid item xs={12}>
              <Paper className='dashboard-paper-left'></Paper>
            </Grid>
          </Grid>
        </div>
      </Fragment>
    );
  }
}

export default Dashboard;
