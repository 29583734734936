const CommonUtil = {
  isValidEmail(email) {
    if (email === undefined || email === '') return true;
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  },
  isValidMobile(mobile) {
    if (mobile === undefined || mobile === '') return true;
    const re = /^(\+\d{1,3}[- ]?)?\d{10,12}$/;
    return re.test(String(mobile));
  },
  isValidName(name) {
    if (name === undefined || name === '') return true;
    var regex = /^[a-zA-Z ]{2,30}$/;
    return regex.test(name);
  },
  isAlphaNumeric(data) {
    if (data === undefined || data === '') return true;
    var regex = /^[a-z0-9]+$/i;
    return regex.test(data);
  },

  isValidNumericName(name) {
    if (name === undefined || name === '') return true;
    var regex = /^[A-Za-z0-9 ]+$/;
    return regex.test(name);
  },

  isValidCode(code) {
    if (code === undefined || code === '') return true;
    var regex = /^[a-zA-Z0-9_-]*$/;
    return regex.test(code);
  },

  isEmptyString(val) {
    if (val === null || val === undefined || val === '') return true;
    else return false;
  },

  isEmpty(obj) {
    if (obj === null || obj === undefined) return true;
    return Object.keys(obj).length === 0;
  },

  getCompany() {
    return localStorage ? localStorage.getItem('companyId') : null;
  },
  TreeDataFormat(arr) {
    var arr1 = [];
    for (var i = 0; i < arr.length; i++) {
      var obj = {};
      obj['value'] = arr[i].id;
      obj['label'] = arr[i].name;
      obj['parentid'] = arr[i].parentId;
      arr1.push(obj);
    }
    var tree = [],
      mappedArr = {},
      arrElem,
      mappedElem;

    // First map the nodes of the array to an object -> create a hash table.
    for (var k = 0, len = arr1.length; k < len; k++) {
      arrElem = arr1[k];
      mappedArr[arrElem.value] = arrElem;
      mappedArr[arrElem.value]['children'] = [];
    }

    for (var value in mappedArr) {
      if (mappedArr.hasOwnProperty(value)) {
        mappedElem = mappedArr[value];
        // If the element is not at the root level, add it to its parent array of children.
        if (mappedElem.parentid) {
          try {
            mappedArr[mappedElem['parentid']]['children'].push(mappedElem);
          } catch (err) {}
        }
        // If the element is at the root level, add it to first level elements array.
        else {
          tree.push(mappedElem);
        }
      }
    }
    return tree;
  },

  ZoneDataFormat(arr) {
    var arr1 = [];
    for (var i = 0; i < arr.length; i++) {
      var obj = {};
      obj['key'] = arr[i].id;
      obj['value'] = arr[i].id;
      obj['title'] = arr[i].name;
      obj['parentid'] = arr[i].parentId;
      arr1.push(obj);
    }
    var tree = [],
      mappedArr = {},
      arrElem,
      mappedElem;

    // First map the nodes of the array to an object -> create a hash table.
    for (var k = 0, len = arr1.length; k < len; k++) {
      arrElem = arr1[k];
      mappedArr[arrElem.value] = arrElem;
      mappedArr[arrElem.value]['children'] = [];
    }

    for (var value in mappedArr) {
      if (mappedArr.hasOwnProperty(value)) {
        mappedElem = mappedArr[value];
        // If the element is not at the root level, add it to its parent array of children.
        if (mappedElem.parentid) {
          mappedArr[mappedElem['parentid']]['children'].push(mappedElem);
        }
        // If the element is at the root level, add it to first level elements array.
        else {
          tree.push(mappedElem);
        }
      }
    }
    return tree;
  },
  sentenceCase(str) {
    if (str === null || str === '') return false;
    else str = str.toString();

    return str.replace(/\w\S*/g, function(txt) {
      return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
    });
  },
};

export default CommonUtil;
