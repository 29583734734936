//OAuth-client
export const REACT_URI_LANDING = '/orm/';
export const REACT_URI_REGISTER_USER = '/orm/register-user';
export const REACT_URI_FORGOT_PASSWORD = '/orm/forgot-password';
export const REACT_URI_CREATE_COMPANY = '/orm/create-company';
export const REACT_URI_SWITCH_COMPANY = '/orm/switch-company';
//Dashboard
export const REACT_URI_DASHBOARD = '/orm/dashboard';

export const REACT_URI_DEPARTMENT = '/orm/department';
export const REACT_URI_DEPARTMENT_DETAILS = '/orm/department-details';

export const REACT_URI_DESIGNATION = '/orm/designation';
export const REACT_URI_DESIGNATION_DETAILS = '/orm/designation-details';

//Personal details
export const REACT_URI_PERSON = '/orm/person';
export const REACT_URI_PERSON_DETAILS = '/orm/person-details';

//Site Management
export const REACT_URI_ADDRESS_TYPE = '/orm/address-type';
export const REACT_URI_ADD_ADDRESS_TYPE = '/orm/addAddresstype';
export const REACT_URI_ADDRESS_TYPE_DETAILS = '/orm/addresstype-details';
export const REACT_URI_SITE_TYPE = '/orm/sitetype';
export const REACT_URI_SITE_TYPE_DETAILS = '/orm/sitetype-details';
export const REACT_URI_SITE = '/orm/site';
export const REACT_URI_SITE_DETAILS = '/orm/site-details';
export const REACT_URI_ZONE = '/orm/zone';
export const REACT_URI_ZONE_DETAILS = '/orm/zone-details';

export const REACT_URI_APPLICATIONS = '/orm/application';
export const REACT_URI_APPLICATIONS_PERSON = '/orm/application';
