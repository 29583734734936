import { combineReducers } from 'redux';
import ThemeOptions from './ThemeOptions';
import errorReducer from './errorReducer';
import DashboardReducer from './DashboardReducer';
import DepartmentReducer from './DepartmentReducer';
import DesignationReducer from './DesignationReducer';
import AddressTypeReducer from './AddressTypeReducer';
import SiteTypeReducer from './SiteTypeReducer';
import SiteReducer from './SiteReducer';
import ZoneReducer from './ZoneReducer';
import securityReducer from './securityReducer';
import PersonReducer from './PersonReducer';
import AddressReducer from './AddressReducer';

export default combineReducers({
  security: securityReducer,
  errors: errorReducer,
  ThemeOptions: ThemeOptions,
  dashBoard: DashboardReducer,
  person: PersonReducer,
  address: AddressReducer,
  department: DepartmentReducer,
  designation: DesignationReducer,
  siteType: SiteTypeReducer,
  addressType: AddressTypeReducer,
  site: SiteReducer,
  zone: ZoneReducer,
});
