import { Box, Grid, Tooltip, IconButton } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import DataTable from '../../components/DataTable';
import { useTranslation } from 'react-i18next';
import Toolbar from '../../components/Toolbar';
import { useDispatch, useSelector } from 'react-redux';
import { deleteZone, getZones } from '../../actions/ZoneApiCalls';
import EditIcon from '@material-ui/icons/Edit';

import AddZone from './AddZone';
import { toast } from 'react-toastify';
import { confirmAlert } from 'react-confirm-alert';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import FilterZone from './FilterZone';
import Pagination from '../../components/Pagination';
import CommonUtil from '../../components/Util/CommonUtils';
import Breadcrumb from '../../components/Breadcrumb';
import ConfirmModal from '../../components/ConfirmModal';

import { ReactComponent as Add } from '../../assets/icons/Add.svg';
import { ReactComponent as Edit } from '../../assets/icons/Edit.svg';
import { ReactComponent as Delete } from '../../assets/icons/Delete.svg';
import { ReactComponent as Refresh } from '../../assets/icons/Refresh.svg';
export default function Zone() {
  const dispatch = useDispatch();
  const store = useSelector((state) => state);
  const { t } = useTranslation();
  const [data, setData] = useState([]);
  const [selectedData, onSelectionChange] = useState([]);
  const [openEditDialog, setOpenEditDialog] = useState(false);
  const [updateObject, setUpdateObject] = useState({});
  const [page, setPage] = React.useState(0);
  const [totalRecords, setTotalRecords] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const [state, setState] = React.useState({
    id: '',
    name: '',
    parentId: '',
    description: '',
    siteId: '',
  });
  useEffect(() => {
    loadZones();
  }, []);

  useEffect(() => {
    if (!CommonUtil.isEmpty(store.zone.zoneList)) {
      setTotalRecords(store.zone.zoneList.totalCount);
      setPage(store.zone.zoneList.currentPage - 1);
    } else {
      setTotalRecords(0);
      setPage(0);
    }
    setData(store.zone.zoneList.zone);
  }, [store.zone.zoneList]);

  const loadZones = () => {
    dispatch(getZones(page + 1, rowsPerPage, true));
  };

  const handleChangePage = (newPage) => {
    setPage(newPage);
    dispatch(getZones(newPage + 1, rowsPerPage, true));
  };

  const handleChangeRowsPerPage = (pageSize) => {
    setRowsPerPage(pageSize);
    setPage(0);
    dispatch(getZones(1, pageSize, true));
  };

  const openAddZoneDialog = () => {
    setUpdateObject(state);
    setOpenEditDialog(true);
  };

  const deleteRecord = (data) => {
    if (data.length < 1) {
      toast.warning(t('COMMON019'));
      return;
    }
    var idsToDelete = [];
    data.map((record, index) => {
      idsToDelete[index] = record.id;
    });
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <ConfirmModal
            open={true}
            text={t('COMMON018')}
            onConfirm={() => {
              dispatch(
                deleteZone(idsToDelete, (data) => {
                  handleError(data);
                })
              );
              onClose();
            }}
            onClose={() => onClose()}
          />
        );
      },
    });
  };

  const handleError = (data) => {
    switch (data.code) {
      case 'LMSI0019':
        toast.success(data.message);
        loadZones();
        break;
      case 'LMSW0001':
        if (data.data.successCount !== 0) {
          toast.success(data.data.successCount + ' ' + t('COMMON048'));
        }
        data = data.data.error;
        data.map(function(name, index) {
          toast.error(data[index].message + ' ' + data[index].data.name);
        });
        break;
      default:
        toast.error(data.message);
        break;
    }
  };

  const breadcrumb = [{ path: '', name: t('ZONE001') }];

  const columns = [
    {
      field: 'name',
      title: t('COMMON044'),
      headerStyle: {
        color: 'black',
        backgroundColor: 'transparent',
      },
    },
    { field: 'parentName', title: t('COMMON050'), editable: 'never' },
    { field: 'description', title: t('ZONE009'), editable: 'never' },
  ];

  const options = { actionsColumnIndex: -1, search: false, selection: true, toolbar: false, paging: true, minBodyHeight: '40vh', initialPage: 1, pageSize: rowsPerPage };

  const actions = [
    {
      icon: () => <Edit color='secondary' />,
      tooltip: t('COMMON005'),
      position: 'row',
      onClick: (event, rowData) => {
        setUpdateObject(rowData);
        setOpenEditDialog(true);
      },
    },
    {
      icon: () => <Delete color='secondary' />,
      tooltip: t('COMMON004'),
      position: 'row',
      onClick: (event, rowData) => {
        deleteRecord([rowData]);
      },
    },
  ];

  return (
    <Grid>
      <Breadcrumb links={breadcrumb}>
        <Tooltip title={t('COMMON009')}>
          <IconButton color='secondary' aria-label={t('COMMON009')} onClick={openAddZoneDialog}>
            <Add style={{ height: 35, width: 35 }} />
          </IconButton>
        </Tooltip>
      </Breadcrumb>

      <Toolbar>
        <Box p={1}>
          <Tooltip title={t('COMMON002')}>
            <Refresh onClick={() => loadZones()} />
          </Tooltip>
        </Box>

        <Box p={1} flexGrow={1}>
          <Tooltip title={t('COMMON004')}>
            <Delete onClick={() => deleteRecord(selectedData)} />
          </Tooltip>
        </Box>
        <Box p={1}>
          <FilterZone props={state} />
        </Box>
      </Toolbar>
      <DataTable
        columns={columns}
        data={data}
        actions={actions}
        onSelectedRoles={(rows) => onSelectionChange(rows)}
        options={options}
        count={totalRecords}
        page={page}
        onChangePage={(newPage) => handleChangePage(newPage)}
        rowsPerPage={rowsPerPage}
        onChangeRowsPerPage={(pageSize) => handleChangeRowsPerPage(pageSize)}
      />
      {openEditDialog && (
        <AddZone
          selectedObject={updateObject}
          open={openEditDialog}
          onClose={() => {
            setOpenEditDialog(false);
            loadZones();
          }}
        />
      )}
    </Grid>
  );
}
