import React, { useState, useEffect } from 'react';
import { deletePerson, getPersons } from '../../actions/PersonApiCalls';
import { useDispatch } from 'react-redux';
import Headerbar from '../../components/HeaderBar';
import { useTranslation } from 'react-i18next';
import AddIcon from '@material-ui/icons/Add';
import RefreshOutlinedIcon from '@material-ui/icons/RefreshOutlined';
import DeleteOutlinedIcon from '@material-ui/icons/DeleteOutlined';
import { toast } from 'react-toastify';
import { confirmAlert } from 'react-confirm-alert';
import ConfirmModal from '../../components/ConfirmModal';
import DataTable from '../../components/DataTable';
import EditIcon from '@material-ui/icons/Edit';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import Pagination from '../../components/Pagination';
import UpdatePerson from './UpdatePerson';
import CommonUtil from '../../components/Util/CommonUtils';
import { useSelector } from 'react-redux';
import { Box, Grid, IconButton } from '@material-ui/core';
import Toolbar from '../../components/Toolbar';
import { Tooltip, Fab } from '@material-ui/core';
import AddPerson from './AddPerson';
import { Avatar } from 'antd';
import FilterPerson from './FilterPerson';
import Breadcrumb from '../../components/Breadcrumb';
import { ReactComponent as Add } from '../../assets/icons/Add.svg';
import { ReactComponent as Edit } from '../../assets/icons/Edit.svg';
import { ReactComponent as Delete } from '../../assets/icons/Delete.svg';
import { ReactComponent as Refresh } from '../../assets/icons/Refresh.svg';

export default function Person() {
  const dispatch = useDispatch();
  const store = useSelector((state) => state);
  const { t } = useTranslation();
  const [data, setData] = useState([]);
  const [selectedData, onSelectionChange] = useState([]);
  const [openEditDialog, setOpenEditDialog] = useState(false);
  const [openAddDialog, setOpenAddDialog] = useState(false);
  const [updateObject, setUpdateObject] = useState({});

  const [page, setPage] = React.useState(0);
  const [totalRecords, setTotalRecords] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const breadcrumb = [{ path: '', name: t('PERSON001') }];

  const [state, setState] = React.useState({
    id: '',
    firstName: '',
    lastName: '',
    code: '',
    email: '',
    mobile: '',
    personName: '',
    designationName: '',
  });

  useEffect(() => {
    loadPersons();
  }, []);

  useEffect(() => {
    if (!CommonUtil.isEmpty(store.person.personList)) {
      setTotalRecords(store.person.personList.totalCount);
      setPage(store.person.personList.currentPage - 1);
    } else {
      setTotalRecords(0);
      setPage(0);
    }
    setData(store.person.personList.employee);
  }, [store.person.personList]);

  const loadPersons = () => {
    dispatch(getPersons(page + 1, rowsPerPage, true));
  };

  const handleChangePage = (newPage) => {
    setPage(newPage);
    dispatch(getPersons(newPage + 1, rowsPerPage, true));
  };

  const handleChangeRowsPerPage = (newPage) => {
    setRowsPerPage(newPage);
    setPage(0);
    dispatch(getPersons(newPage + 1, rowsPerPage, true));
  };

  const openAddEmployeeDialog = () => {
    setOpenAddDialog(true);
  };

  const deleteRecord = (data) => {
    if (data.length < 1) {
      toast.warning(t('COMMON019'));
      return;
    }
    var idsToDelete = [];
    data.map((record, index) => {
      idsToDelete[index] = record.id;
    });
    var str = idsToDelete.join(',');
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <ConfirmModal
            open={true}
            text={t('COMMON018')}
            onConfirm={() => {
              dispatch(
                deletePerson(str, (data) => {
                  handlError(data);
                })
              );
              onClose();
            }}
            onClose={() => onClose()}
          />
        );
      },
    });
  };

  const handlError = (data) => {
    switch (data.code) {
      case 'OMSI0008':
        toast.success(data.message);
        loadPersons();
        break;
      default:
        toast.error(data.message);
        break;
    }
  };

  const columns = [
    {
      title: 'Avatar',
      field: 'profilePhoto',
      render: (client) => {
        const photo = client.profilePhoto;
        if (photo === undefined) return '';
        else return <img src={photo} style={{ height: 60, width: 70, borderRadius: '50%' }} />;
      },
    },

    {
      render: (client) => {
        const firstName = client.firstName === undefined ? '' : client.firstName;
        const lastName = client.lastName === undefined ? '' : client.lastName;
        const employeeCode = client.employeeCode === undefined ? '' : client.employeeCode;
        return firstName + '\n' + lastName + ' (' + employeeCode + ')';
      },
      title: t('PERSON012'),
    },
    {
      render: (client) => {
        const email = client.email === undefined ? '' : client.email;
        const phone = client.phone === undefined ? '' : client.phone;
        return email + '\n' + phone;
      },
      title: t('PERSON014'),
      editable: 'never',
    },
    {
      field: 'departmentName',
      title: t('PERSON015'),
    },
  ];

  const options = { actionsColumnIndex: -1, search: false, selection: true, toolbar: false, paging: true, minBodyHeight: '40vh', initialPage: 1, pageSize: rowsPerPage };

  const actions = [
    {
      icon: () => <Edit color='secondary' />,
      tooltip: t('COMMON005'),
      position: 'row',
      onClick: (event, rowData) => {
        setUpdateObject(rowData);
        setOpenEditDialog(true);
      },
    },
    {
      icon: () => <Delete color='secondary' />,
      tooltip: t('COMMON004'),
      position: 'row',
      onClick: (event, rowData) => {
        deleteRecord([rowData]);
      },
    },
  ];

  return (
    <Grid>
      <Breadcrumb links={breadcrumb}>
        <Tooltip title={t('COMMON009')}>
          <IconButton color='secondary' aria-label={t('COMMON009')} onClick={openAddEmployeeDialog}>
            <Add style={{ height: 35, width: 35 }} />
          </IconButton>
        </Tooltip>
      </Breadcrumb>
      <Toolbar>
        <Box p={1}>
          <Tooltip title={t('COMMON002')}>
            <Refresh onClick={loadPersons} />
          </Tooltip>
        </Box>
        <Box p={1} flexGrow={1}>
          <Tooltip title={t('COMMON004')}>
            <Delete onClick={() => deleteRecord(selectedData)} />
          </Tooltip>
        </Box>
        <Box p={1}>
          <FilterPerson props={state} />
        </Box>
      </Toolbar>

      <DataTable
        columns={columns}
        data={data}
        actions={actions}
        onSelectedRoles={(rows) => onSelectionChange(rows)}
        options={options}
        count={totalRecords}
        page={page}
        onChangePage={(newPage) => handleChangePage(newPage)}
        rowsPerPage={rowsPerPage}
        onChangeRowsPerPage={(pageSize) => handleChangeRowsPerPage(pageSize)}
      />

      {openEditDialog && (
        <UpdatePerson
          selectedObject={updateObject}
          open={openEditDialog}
          onClose={() => {
            setOpenEditDialog(false);
            loadPersons();
          }}
        />
      )}

      {openAddDialog && (
        <AddPerson
          open={openAddDialog}
          onClose={() => {
            setOpenAddDialog(false);
          }}
        />
      )}
    </Grid>
  );
}
