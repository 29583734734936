import { Accordion, AccordionDetails, AccordionSummary, DialogContent, Paper, Tab, Tabs } from '@material-ui/core';
import DataTable from '../../components/DataTable';
import { Grid, Box, MenuItem, Select, Typography, FormControl, TextField, Dialog } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import SubmitButton from '../../components/SubmitButton';
import ProfilePic from '../../assets/images/profile.png';
import React, { useEffect } from 'react';
import { DialogTitle } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import EditPerson from './EditPerson';
import { Address } from './Address';
import { Avatar } from 'antd';
import AddAPhotoSharpIcon from '@material-ui/icons/AddAPhotoSharp';
import { ReactComponent as Cancel } from '../../assets/icons/Cancel.svg';

function TabPanel(props) {
  const { selectedObject, onClose, children, value, index, ...other } = props;

  return (
    <div role='tabpanel' hidden={value !== index} id={`vertical-tabpanel-${index}`} aria-labelledby={`vertical-tab-${index}`} {...other}>
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `vertical-tab-${index}`,
    'aria-controls': `vertical-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
    display: 'flex',
    height: 224,
  },
  tabs: {
    borderRight: `1px solid ${theme.palette.divider}`,
    // maxHeight: '100px',
  },
  // rightTabs: {
  //   maxHeight: '500px',
  // },
}));

export default function UpdatePerson(props) {
  const { t } = useTranslation();

  const handleClose = () => {
    props.onClose(false);
  };
  const classes = useStyles();
  const [value, setValue] = React.useState(0);
  const [open, setOpen] = React.useState(props.open);
  const handleChange1 = (event, newValue) => {
    setValue(newValue);
  };
  const handleFileChange = () => {};

  return (
    <div className={classes.root}>
      <Dialog fullWidth={true} maxWidth='lg' open={open} onClose={handleClose} aria-labelledby='form-dialog-title' className='custom-modal'>
        <DialogTitle>
          <Box display='flex'>
            <Box flexGrow={1}>{t('PERSON013')}</Box>
            <Cancel onClick={handleClose} style={{ height: 25, width: 25 }} />
          </Box>
        </DialogTitle>
        <DialogContent style={{ padding: '0' }}>
          <Grid container>
            <Grid item sm={4} md={3} style={{ boxShadow: '1px -5px 10px #00000029', position: 'relative', zIndex: '999999' }}>
              <Grid container justify='center'>
                <Grid item xs={12}>
                  <Box display='flex' justifyContent='center' m={1} p={1}>
                    <div style={{ alignItems: 'center' }}>
                      {/* <SubmitButton component='label' size='small'> */}
                      {/* <Avatar>
                        <AddAPhotoSharpIcon />
                      </Avatar> */}
                      {/* <input id='profilePhoto' name='profilePhoto' type='file' accept='image/*' hidden /> */}
                      {props.selectedObject.profilePhoto != undefined && <img src={props.selectedObject.profilePhoto} style={{ height: 50, width: 50, borderRadius: '50%' }}></img>}
                      {/* </SubmitButton> */}
                    </div>
                  </Box>
                </Grid>
              </Grid>
              <Tabs orientation='vertical' variant='scrollable' value={value} onChange={handleChange1} aria-label='Vertical tabs example' className={classes.tabs}>
                <Tab label={t('PERSON040')} {...a11yProps(0)} paper />
                <Tab label={t('PERSON041')} {...a11yProps(3)} paper />
                <Tab label={t('PERSON042')} {...a11yProps(1)} paper />
              </Tabs>
            </Grid>
            <Grid item sm={8} md={9} className={classes.rightTabs}>
              <TabPanel value={value} index={0}>
                <EditPerson onClose={props.onClose} selectedObject={props.selectedObject} />
              </TabPanel>
              <TabPanel value={value} index={1}>
                <Address onClose={props.onClose} selectedObject={props.selectedObject} />
              </TabPanel>
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>
    </div>
  );
}
