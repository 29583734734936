import 'react-app-polyfill/stable';
import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import axios from 'axios';
import { I18nextProvider } from 'react-i18next';
import i18n from './components/Util/i18n';
import { REACT_URI_LANDING } from './actions/EndPoints';
import { toast } from 'react-toastify';

serviceWorker.unregister();

axios.interceptors.response.use(
  function(response) {
    return response;
  },
  function(error) {
    if (504 === error.response.status) {
      toast.error('Server is Unreachable.');
    }
    if (401 === error.response.status) {
      localStorage.removeItem('ormToken');
      window.location = REACT_URI_LANDING;
    } else {
      return Promise.reject(error);
    }
  }
);

axios.interceptors.request.use(
  (config) => {
    // const language = '?language=' + BrowserLanguage.getDefaultLanguage();
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

ReactDOM.render(
  <I18nextProvider i18n={i18n}>
    <App props={i18n} />
  </I18nextProvider>,
  document.getElementById('root')
);
