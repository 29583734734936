import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import { Fragment } from 'react';
import { REACT_URI_LANDING, REACT_URI_SWITCH_COMPANY } from '../actions/EndPoints';
import biozlink from '../assets/images/onboard/access-cloud.png';
import cloudAccess from '../assets/images/onboard/cloudaccess.png';
import { Box, Card, CardContent } from '@material-ui/core';
import { toast } from 'react-toastify';
import CommonUtil from '../components/Util/CommonUtils';
import { registerUser } from '../actions/UserApiCalls';
import { makeStyles } from '@material-ui/styles';
import BusinessIcon from '@material-ui/icons/Business';
import Link from '@material-ui/core/Link';
import Button from '@material-ui/core/Button';
import EmailIcon from '@material-ui/icons/Email';
import LocalPhoneIcon from '@material-ui/icons/LocalPhone';
import ApartmentIcon from '@material-ui/icons/Apartment';
import { Link as RouterLink } from 'react-router-dom';
import SubmitButton from '../components/SubmitButton';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  form: {
    width: '100%',
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
    backgroundColor: '#1090E5',
    borderRadius: '0.2rem',
    padding: '10px',
    '&:hover': {
      background: '#006fb9',
    },
  },
  container: {
    height: '100%',
    width: '100%',
    justifyContent: 'center',
    alignItems: 'center',
    display: 'flex',
  },
}));

export default function CreateUser(props) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  let history = useHistory();
  const classes = useStyles();

  const [state, setState] = React.useState({
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
    password: '',
    cPassword: '',
    verified: true,
  });

  const [error, setError] = React.useState({
    firstName: false,
    lastName: false,
    email: false,
    phone: false,
    password: false,
    cPassword: false,
  });

  const handleChange = (event) => {
    const name = event.target.name;
    setState({
      ...state,
      [name]: event.target.value,
    });

    setError({
      [name]: false,
    });
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    if (isFormValid(state)) {
      dispatch(
        registerUser(state, (data) => {
          handlError(data);
        })
      );
    }
  };

  const isFormValid = (event) => {
    if (!CommonUtil.isValidName(state.firstName)) {
      setError({ firstName: true });
      toast.error(t('USER0008'));
      return false;
    }
    if (!CommonUtil.isValidName(state.lastName)) {
      setError({ lastName: true });
      toast.error(t('USER0009'));
      return false;
    }
    if (!CommonUtil.isValidEmail(state.email)) {
      setError({ email: true });
      toast.error(t('USER0006'));
      return false;
    }
    if (!CommonUtil.isValidMobile(state.phone)) {
      setError({ phone: true });
      toast.error(t('USER0007'));
      return false;
    }
    if (state.password !== state.cPassword) {
      setError({ cPassword: true });
      toast.error(t('USER0010'));
      return false;
    }
    return true;
  };

  const handlError = (data) => {
    switch (data.code) {
      case 'UASE0001':
        setError({ email: true });
        break;
      case 'UASE0020':
        setError({ email: true });
        setError({ phone: true });
        break;
      case 'UASE0002':
        setError({ password: true });
        break;
      case 'UASI0001':
        history.push(REACT_URI_LANDING);
        break;
      default:
        break;
    }
    if (data.code !== 'UASI0001') {
      toast.error(data.message);
    } else {
      toast.success(data.message);
    }
  };

  return (
    <div className='background-image'>
      <Container component='main' maxWidth='md' className={classes.container}>
        <Grid container className='main_conatiner'>
          <Grid item sm={4} xs={12} className='paper_left'>
            <img src={biozlink} alt='logo' style={{ width: '250px', paddingTop: '50px' }} />
            <img src={cloudAccess} alt='logo' className='left_img' />
          </Grid>
          <Grid item sm={8} xs={12} className='paper_right'>
            <Grid container spacing={5} alignItems='center'>
              <Grid item></Grid>
              <Grid item xs>
                <Typography className='signin_title' variant='h5' color='textPrimary' align='center'>
                  {t('projectname')}

                  {t('COMMON041')}
                </Typography>
              </Grid>
            </Grid>
            <form className={classes.form} onSubmit={handleSubmit}>
              <Grid container spacing={2} alignItems='center'>
                <Grid item>
                  <BusinessIcon className='login_icons' />
                </Grid>
                <Grid item xs>
                  <TextField
                    error={error.firstName}
                    className='login_input'
                    size='small'
                    variant='outlined'
                    margin='normal'
                    required
                    fullWidth
                    label={t('USER0001')}
                    name='firstName'
                    type='text'
                    value={state.firstName}
                    onChange={handleChange}
                  />
                </Grid>
              </Grid>
              <Grid container spacing={2} alignItems='center'>
                <Grid item>
                  <BusinessIcon className='login_icons' />
                </Grid>
                <Grid item xs>
                  <TextField
                    error={error.lastName}
                    className='login_input'
                    size='small'
                    variant='outlined'
                    margin='normal'
                    required
                    fullWidth
                    label={t('USER0002')}
                    name='lastName'
                    type='text'
                    value={state.lastName}
                    onChange={handleChange}
                  />
                </Grid>
              </Grid>

              <Grid container spacing={2} alignItems='center'>
                <Grid item>
                  <EmailIcon className='login_icons' />
                </Grid>
                <Grid item xs>
                  <TextField
                    error={error.email}
                    className='login_input'
                    size='small'
                    variant='outlined'
                    margin='normal'
                    required
                    fullWidth
                    label={t('COMMON042')}
                    name='email'
                    type='text'
                    value={state.email}
                    onChange={handleChange}
                  />
                </Grid>
              </Grid>
              <Grid container spacing={2} alignItems='center'>
                <Grid item>
                  <LocalPhoneIcon className='login_icons' />
                </Grid>
                <Grid item xs>
                  <TextField
                    error={error.phone}
                    className='login_input'
                    size='small'
                    variant='outlined'
                    margin='normal'
                    required
                    fullWidth
                    label={t('COMMON043')}
                    name='phone'
                    type='text'
                    value={state.phone}
                    onChange={handleChange}
                  />
                </Grid>
              </Grid>
              <Grid container spacing={2} alignItems='center'>
                <Grid item>
                  <BusinessIcon className='login_icons' />
                </Grid>
                <Grid item xs>
                  <TextField
                    error={error.password}
                    className='login_input'
                    size='small'
                    variant='outlined'
                    margin='normal'
                    required
                    fullWidth
                    label={t('USER0003')}
                    name='password'
                    type='password'
                    value={state.password}
                    onChange={handleChange}
                  />
                </Grid>
              </Grid>
              <Grid container spacing={2} alignItems='center'>
                <Grid item>
                  <BusinessIcon className='login_icons' />
                </Grid>
                <Grid item xs>
                  <TextField
                    error={error.cPassword}
                    className='login_input'
                    size='small'
                    variant='outlined'
                    margin='normal'
                    required
                    fullWidth
                    label={t('USER0004')}
                    name='cPassword'
                    type='password'
                    value={state.cPassword}
                    onChange={handleChange}
                  />
                </Grid>
              </Grid>
              <Grid container spacing={5} alignItems='center'>
                <Grid item></Grid>
                <Grid item xs>
                  <Button type='submit' fullWidth variant='contained' color='primary' variant='contained'>
                    {t('COMMON007')}
                  </Button>
                </Grid>
              </Grid>
              <Grid container alignItems='center'>
                <Grid item xs></Grid>
                <Grid item>
                  <Button onClick={history.goBack} style={{ lineHeight: '30px', color: '#1090E5', fontSize: '13px' }}>
                    {t('COMMON040')}
                  </Button>
                </Grid>
              </Grid>
            </form>
          </Grid>
        </Grid>
      </Container>
    </div>
  );
}
