import React from 'react';
import { Button, withStyles } from '@material-ui/core';

export default function SubmitButton(props) {
  const ColorButton = withStyles((theme) => ({
    root: {
      backgroundColor: 'none',
      color: 'white',
      opacity: 100,
      fontWeight: 100,
      minWidth: '15%',
      '&:hover': {
        backgroundColor: '#115293',
      },
    },
  }))(Button);

  return <ColorButton {...props} />;
}
