import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import { REACT_URI_DASHBOARD, REACT_URI_LANDING, REACT_URI_SWITCH_COMPANY } from '../actions/EndPoints';
import { makeStyles } from '@material-ui/core';
import { createNewCompany } from '../actions/CompanyApiCalls';
import CommonUtil from '../components/Util/CommonUtils';
import { toast } from 'react-toastify';
import biozlink from '../assets/images/onboard/access-cloud.png';
import cloudAccess from '../assets/images/onboard/cloudaccess.png';
import './custom.css';
import Link from '@material-ui/core/Link';
import Button from '@material-ui/core/Button';
import EmailIcon from '@material-ui/icons/Email';
import LocalPhoneIcon from '@material-ui/icons/LocalPhone';
import ApartmentIcon from '@material-ui/icons/Apartment';
import BusinessIcon from '@material-ui/icons/Business';
import { Link as RouterLink } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  form: {
    width: '100%',
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
    backgroundColor: '#1090E5',
    borderRadius: '0.2rem',
    padding: '10px',
    '&:hover': {
      background: '#006fb9',
    },
  },
  container: {
    height: '100%',
    width: '100%',
    justifyContent: 'center',
    alignItems: 'center',
    display: 'flex',
  },
}));

export default function CreateCompany(props) {
  const classes = useStyles();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  let history = useHistory();

  const [state, setState] = React.useState({
    name: '',
    code: '',
    email: '',
    phone: '',
    registrationNumber: '',
  });

  const [error, setError] = React.useState({
    name: false,
    code: false,
    email: false,
    phone: false,
    registrationNumber: false,
  });

  const handleChange = (event) => {
    const name = event.target.name;
    setState({
      ...state,
      [name]: event.target.value,
    });

    setError({
      [name]: false,
    });
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    if (isFormValid(state)) {
      dispatch(
        createNewCompany(state, (data) => {
          handlError(data);
        })
      );
    }
  };

  const isFormValid = (event) => {
    if (!CommonUtil.isValidName(state.name)) {
      setError({ name: true });
      toast.error(t('COMP0004'));
      return false;
    }
    if (!CommonUtil.isValidCode(state.code)) {
      setError({ code: true });
      toast.error(t('COMP0005'));
      return false;
    }
    if (!CommonUtil.isAlphaNumeric(state.registrationNumber)) {
      setError({ registrationNumber: true });
      toast.error(t('COMP0006'));
      return false;
    }
    if (!CommonUtil.isValidEmail(state.email)) {
      setError({ email: true });
      toast.error(t('COMP0007'));
      return false;
    }
    if (!CommonUtil.isValidMobile(state.phone)) {
      setError({ phone: true });
      toast.error(t('COMP0008'));
      return false;
    }
    return true;
  };

  const handlError = (data) => {
    switch (data.code) {
      case 'UASE0006':
        setError({ name: true });
        break;
      case 'UASE0011':
        setError({ code: true });
        break;
      case 'UASE0012':
        setError({ registrationNumber: true });
        break;
      case 'UASI0011':
        history.push(REACT_URI_DASHBOARD);
        break;
      default:
        break;
    }
    if (data.code !== 'UASI0011') {
      if (data.code !== 'UASI0006') {
        toast.error(data.message);
      } else {
        toast.success(t('COMP0010') + data.data.code + t('COMP0011'));
      }
    }
  };

  return (
    <div className='background-image'>
      <Container component='main' maxWidth='md' className={classes.container}>
        <Grid container className='main_conatiner'>
          <Grid item sm={4} xs={12} className='paper_left'>
            <img src={biozlink} alt='logo' style={{ width: '250px', paddingTop: '50px' }} />
            <img src={cloudAccess} alt='logo' className='left_img' />
          </Grid>
          <Grid item sm={8} xs={12} className='paper_right'>
            <Grid container spacing={5} alignItems='center'>
              <Grid item></Grid>
              <Grid item xs>
                <Typography className='signin_title' variant='h5' color='textPrimary' align='center'>
                  Create Company
                </Typography>
              </Grid>
            </Grid>
            <form className={classes.form} noValidate onSubmit={handleSubmit}>
              <Grid container spacing={2} alignItems='center'>
                <Grid item>
                  <BusinessIcon className='login_icons' />
                </Grid>
                <Grid item xs>
                  <TextField
                    error={error.name}
                    className='login_input'
                    size='small'
                    variant='outlined'
                    margin='normal'
                    required
                    fullWidth
                    id='companyName'
                    label={t('COMMON044')}
                    autoComplete='companyName'
                    name='name'
                    type='text'
                    value={state.name}
                    onChange={handleChange}
                  />
                </Grid>
              </Grid>

              <Grid container spacing={2} alignItems='center'>
                <Grid item>
                  <BusinessIcon className='login_icons' />
                </Grid>
                <Grid item xs>
                  <TextField
                    error={error.code}
                    className='login_input'
                    size='small'
                    variant='outlined'
                    margin='normal'
                    required
                    fullWidth
                    id='companyCode'
                    label={t('COMMON045')}
                    autoComplete='CompanyCode'
                    name='code'
                    type='text'
                    value={state.code}
                    onChange={handleChange}
                  />
                </Grid>
              </Grid>
              <Grid container spacing={2} alignItems='center'>
                <Grid item>
                  <ApartmentIcon className='login_icons' />
                </Grid>
                <Grid item xs>
                  <TextField
                    error={error.registrationNumber}
                    className='login_input'
                    size='small'
                    variant='outlined'
                    margin='normal'
                    fullWidth
                    id='registerNumber'
                    label={t('COMP0002')}
                    autoComplete='number'
                    name='registrationNumber'
                    type='text'
                    value={state.registrationNumber}
                    onChange={handleChange}
                  />
                </Grid>
              </Grid>
              <Grid container spacing={2} alignItems='center'>
                <Grid item>
                  <EmailIcon className='login_icons' />
                </Grid>
                <Grid item xs>
                  <TextField
                    error={error.email}
                    className='login_input'
                    size='small'
                    variant='outlined'
                    margin='normal'
                    fullWidth
                    id='email'
                    label={t('COMMON042')}
                    autoComplete='email'
                    name='email'
                    type='email'
                    value={state.email}
                    onChange={handleChange}
                  />
                </Grid>
              </Grid>
              <Grid container spacing={2} alignItems='center'>
                <Grid item>
                  <LocalPhoneIcon className='login_icons' />
                </Grid>
                <Grid item xs>
                  <TextField
                    error={error.phone}
                    className='login_input'
                    size='small'
                    variant='outlined'
                    margin='normal'
                    fullWidth
                    id='phoneNumber'
                    label={t('COMMON043')}
                    autoComplete='number'
                    name='phone'
                    type='text'
                    value={state.phone}
                    onChange={handleChange}
                  />
                </Grid>
              </Grid>
              <Grid container spacing={5} alignItems='center'>
                <Grid item></Grid>
                <Grid item xs>
                  <Button type='submit' fullWidth variant='contained' color='primary' className={classes.submit}>
                    {t('COMMON007')}
                  </Button>
                </Grid>
              </Grid>
              <Grid container alignItems='center'>
                <Grid item xs></Grid>
                <Grid item>
                  <Link component={RouterLink} to={REACT_URI_SWITCH_COMPANY} style={{ lineHeight: '30px', color: '#1090E5', fontSize: '13px' }}>
                    {t('COMMON040')}
                  </Link>
                </Grid>
              </Grid>
            </form>
          </Grid>
        </Grid>
      </Container>
    </div>
  );
}
