import React from 'react';
import { Switch, Route } from 'react-router-dom';
import { ThemeProvider } from '@material-ui/styles';
import MuiTheme from './theme';
import { LeftSidebar } from './layout-blueprints';

import store from './config/configureStore';

import jwt_decode from 'jwt-decode';
import { setHeaderToken, logout } from './security-config/LoginActions';
import { SET_CURRENT_USER } from './actions/types';
import SecuredRoute from './security-config/SecureRoute';

import LoginPage from './security-config/LoginPage';
import RegisterUser from './security-config/RegisterUser';
import SwitchCompany from './security-config/SwitchCompany';
import CreateCompany from './security-config/CreateCompany';
import ForgotPassword from './security-config/ForgotPassword';

import {
  REACT_URI_LANDING,
  REACT_URI_CREATE_COMPANY,
  REACT_URI_SWITCH_COMPANY,
  REACT_URI_REGISTER_USER,
  REACT_URI_FORGOT_PASSWORD,
  REACT_URI_DASHBOARD,
  REACT_URI_PERSON,
  REACT_URI_DEPARTMENT,
  REACT_URI_DESIGNATION,
  REACT_URI_ADDRESS_TYPE,
  REACT_URI_SITE_TYPE,
  REACT_URI_SITE,
  REACT_URI_ZONE,
  REACT_URI_APPLICATIONS,
  REACT_URI_APPLICATIONS_PERSON,
} from './actions/EndPoints';

import DashBoard from './components/dashboard/Dashboard';

import Person from './pages/Person/Person';
import Department from './pages/Department/Department';
import Designation from './pages/Designation/Designation';
import AddressType from './pages/AddressType/AddressType';
import SiteType from './pages/SiteType/SiteType';
import Site from './pages/Site/Site';
import Zone from './pages/Zone/Zone';
import Applications from './pages/Membership/Applications';
import Membership from './pages/Membership/Membership';

const ormToken = localStorage.ormToken;

if (ormToken) {
  try {
    setHeaderToken(ormToken);
    const decoded_jwtToken = jwt_decode(ormToken);
    store.dispatch({
      type: SET_CURRENT_USER,
      payload: decoded_jwtToken,
    });

    const currentTime = Date.now() / 1000;
    if (decoded_jwtToken.exp < currentTime) {
      store.dispatch(logout());
      window.location.href = REACT_URI_LANDING;
    }
  } catch (error) {
    localStorage.removeItem('ormToken');
    window.location.href = REACT_URI_LANDING;
  }
} else {
  localStorage.removeItem('ormToken');
}

const Routes = () => {
  return (
    <ThemeProvider theme={MuiTheme}>
      <Switch>
        <Route exact path='/' component={LoginPage} />
        <Route exact path={REACT_URI_LANDING} component={LoginPage} />
        <Route exact path={REACT_URI_REGISTER_USER} component={RegisterUser} />
        <Route exact path={REACT_URI_FORGOT_PASSWORD} component={ForgotPassword} />

        <SecuredRoute exact path={REACT_URI_CREATE_COMPANY} component={CreateCompany} />
        <SecuredRoute exact path={REACT_URI_SWITCH_COMPANY} component={SwitchCompany} />
        <LeftSidebar>
          <SecuredRoute exact path={REACT_URI_DASHBOARD} component={DashBoard} />
          <SecuredRoute exact path={REACT_URI_PERSON} component={Person} />
          <SecuredRoute exact path={REACT_URI_DEPARTMENT} component={Department} />
          <SecuredRoute exact path={REACT_URI_DESIGNATION} component={Designation} />
          <SecuredRoute exact path={REACT_URI_APPLICATIONS} component={Applications} />
          <SecuredRoute exact path={REACT_URI_APPLICATIONS_PERSON + '/:id'} component={Membership} />
          <SecuredRoute exact path={REACT_URI_ADDRESS_TYPE} component={AddressType} />
          <SecuredRoute exact path={REACT_URI_SITE_TYPE} component={SiteType} />
          <SecuredRoute exact path={REACT_URI_SITE} component={Site} />
          <SecuredRoute exact path={REACT_URI_ZONE} component={Zone} />
        </LeftSidebar>
      </Switch>
    </ThemeProvider>
  );
};

export default Routes;
