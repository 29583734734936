import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';

import { REACT_URI_CREATE_COMPANY, REACT_URI_DASHBOARD } from '../actions/EndPoints';
import { Box, makeStyles } from '@material-ui/core';
import { getCompanyList, switchCompany } from '../actions/CompanyApiCalls';
import { toast } from 'react-toastify';

import biozlink from '../assets/images/onboard/access-cloud.png';
import cloudAccess from '../assets/images/onboard/cloudaccess.png';
import './custom.css';
import Link from '@material-ui/core/Link';
import Button from '@material-ui/core/Button';
import CommonUtil from '../components/Util/CommonUtils';
import DomainIcon from '@material-ui/icons/Domain';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { Link as RouterLink } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%',
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(4, 0, 2),
    backgroundColor: '#1090E5',
    borderRadius: '0.2rem',
    padding: '10px',
    '&:hover': {
      background: '#006fb9',
    },
  },
  container: {
    height: '100%',
    width: '100%',
    justifyContent: 'center',
    alignItems: 'center',
    display: 'flex',
  },
}));

export default function SwitchCompany(props) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  let history = useHistory();
  const classes = useStyles();
  const [companies, setCompanies] = useState([]);
  const store = useSelector((state) => state);

  const [state, setState] = React.useState({
    companyCode: 'zkteco',
  });

  const [error, setError] = React.useState({
    companyCode: false,
  });

  const handleChange = (event) => {
    const name = event.target.name;
    setState({
      ...state,
      [name]: event.target.value,
    });

    setError({
      [name]: false,
    });
  };

  useEffect(() => {
    dispatch(getCompanyList());
  }, []);

  useEffect(() => {
    if (!CommonUtil.isEmpty(store.security.companyList)) {
      setCompanies(store.security.companyList);
    }
  }, [store.security.companyList]);

  const handlError = (data) => {
    switch (data.code) {
      case 'UASE0010':
        setError({ companyCode: true });
        break;
      case 'UASE0027':
        setError({ companyCode: true });
        break;
      case 'UASI0011':
        history.push(REACT_URI_DASHBOARD);
        break;
      default:
        break;
    }
    if (data.code !== 'UASI0011') {
      toast.error(data.message);
    }
  };

  return (
    <div className='background-image'>
      <Container component='main' maxWidth='md' className={classes.container}>
        <Grid container className='main_conatiner'>
          <Grid className='paper_left' item sm={4} xs={12}>
            <img src={biozlink} alt='logo' style={{ width: '250px', paddingTop: '50px' }} />
            <img src={cloudAccess} alt='logo' className='left_img' />
          </Grid>
          <Grid
            item
            className='paper_right'
            sm={8}
            xs={12}
            style={{
              justifyContent: 'center',
              alignItems: 'center',
              textAlign: 'center',
            }}
          >
            <Grid container spacing={5} alignItems='center'>
              <Grid item></Grid>
              <Grid item xs>
                <Typography className='signin_title' variant='h5' color='textPrimary' align='center'>
                  Verify Company
                </Typography>
              </Grid>
            </Grid>
            <form className={classes.form} noValidate>
              <Grid container spacing={2} alignItems='center'>
                <Grid item>
                  <DomainIcon className='login_icons' />
                </Grid>
                <Grid item xs>
                  <Autocomplete
                    id='company_code'
                    options={companies}
                    getOptionLabel={(option) => option.code}
                    onChange={(event, newValue) => {
                      if (!CommonUtil.isEmpty(newValue)) {
                        dispatch(
                          switchCompany(newValue.code, (data) => {
                            handlError(data);
                          })
                        );
                      }
                    }}
                    renderInput={(params) => <TextField {...params} className='login_input' variant='outlined' fullWidth label='Company Code' autoFocus />}
                  />
                </Grid>
              </Grid>
            </form>
            <Grid container alignItems='center'>
              <Grid item xs></Grid>
              <Grid item>
                <Button color='primary' className='forgot_password' href='/orm' size={'small'}>
                  {' Back to login'}
                </Button>
              </Grid>
            </Grid>
            <Grid container spacing={5} alignItems='center'>
              <Grid item></Grid>
              <Grid item xs>
                <Box mt={6} align='center'>
                  <Typography variant='body2' color='textPrimary' align='center' style={{ fontSize: '12px' }}>
                    {t('COMP0000')}
                  </Typography>
                  <Link component={RouterLink} to={REACT_URI_CREATE_COMPANY} style={{ lineHeight: '30px', color: '#1090E5', fontSize: '13px' }}>
                    {t('COMP0001')}
                  </Link>
                </Box>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </div>
  );
}
