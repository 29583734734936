import axios from 'axios';

import { JAVA_API_PERSON, JAVA_API_PERSON_SEARCH, JAVA_API_ADDRESS } from './ApiEndPoints';
import { ADDRESS_LIST, PERSON_LIST } from './types';

export const getPersons = (pageNumber, pageSize, isPageable, callBack) => async (dispatch) => {
  try {
    const res = await axios.get(JAVA_API_PERSON + '?page=' + pageNumber + '&size=' + pageSize + '&isPageable=' + isPageable);
    if (res.status === 200 && res.data.code === 'OMSI0000') {
      if (isPageable) {
        dispatch({
          type: PERSON_LIST,
          payload: res.data.data,
        });
      } else {
        callBack(res.data.data);
      }
    } else {
      if (isPageable) {
        dispatch({
          type: PERSON_LIST,
          payload: [],
        });
      }
    }
  } catch {}
};

export const filterPerson = (firstName, lastName, employeeCode, email, phone, page, size) => async (dispatch) => {
  try {
    let query = '';
    if (firstName !== '') {
      query += '&firstName=' + firstName;
    }
    if (lastName !== '') {
      query += '&lastName=' + lastName;
    }
    if (email !== '') {
      query += '&email=' + email;
    }
    if (phone !== '') {
      query += '&phone=' + phone;
    }
    if (employeeCode !== '') {
      query += '&employeeCode=' + employeeCode;
    }
    const res = await axios.get(JAVA_API_PERSON + '/' + 'search' + '?page=' + page + '&size=' + size + query);
    if (res.status === 200 && res.data.code === 'OMSI0000') {
      dispatch({
        type: PERSON_LIST,
        payload: res.data.data,
      });
    } else {
      dispatch({
        type: PERSON_LIST,
        payload: [],
      });
    }
  } catch {}
};

export const addPerson = (payload, callBack) => async (dispatch) => {
  try {
    let res = '';
    res = await axios.post(JAVA_API_PERSON, payload);
    callBack(res.data);
  } catch {}
};

export const updatePerson = (payload, callBack) => async (dispatch) => {
  try {
    const res = await axios.put(JAVA_API_PERSON + '/' + payload.id, payload);
    callBack(res.data);
  } catch {}
};

export const deletePerson = (str, callBack) => async (dispatch) => {
  try {
    const res = await axios.delete(JAVA_API_PERSON + '?id=' + str);
    callBack(res.data);
  } catch {}
};
export const getPersonAddress = (id, callBack) => async (dispatch) => {
  try {
    let query = '';
    if (id !== '') {
      query += '?employeeId=' + id;
    }
    const res = await axios.get(JAVA_API_ADDRESS + query);
    if (res.status === 200 && res.data.code === 'OMSI0000') {
      dispatch({
        type: ADDRESS_LIST,
        payload: res.data.data,
      });
    } else {
      dispatch({
        type: ADDRESS_LIST,
        payload: [],
      });
    }
  } catch {}
};

export const updateAddress = (payload, callBack) => async (dispatch) => {
  try {
    let isAddMode = payload.id === '' ? true : false;

    let res = '';
    if (isAddMode) {
      res = await axios.post(JAVA_API_ADDRESS, payload);
    } else {
      res = await axios.put(JAVA_API_ADDRESS + '/' + payload.id, payload);
    }
    callBack(res.data);
  } catch {}
};
