import { Grid, Box, MenuItem, Select, Typography, FormControl, TextField, Dialog, InputLabel, FormGroup, DialogActions } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import SubmitButton from '../../components/SubmitButton';
import React, { useEffect } from 'react';
import CommonUtil from '../../components/Util/CommonUtils';
import { toast } from 'react-toastify';
import CancelButton from '../../components/CancelButton';
import { getPersonAddress } from '../../actions/PersonApiCalls';
import { updateAddress } from '../../actions/PersonApiCalls';
import { cityData, countryData, stateData, timeZoneData } from '../Site/AddressFormData';
import { getAddressTypes } from '../../actions/AddressTypeApiCalls';

export function Address(props) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const store = useSelector((state) => state);
  const [addressList, setAddressList] = React.useState([]);
  const [siteTypes, setSiteTypes] = React.useState([]);
  const [parentSites, setParentSites] = React.useState([]);
  const [addressTypes, setAddressTypes] = React.useState([]);
  const [countries, setCountries] = React.useState([]);
  const [selectStates, setSelectStates] = React.useState([]);
  const [cities, setCities] = React.useState([]);
  const [timeZones, setTimeZones] = React.useState([]);

  const [state, setState] = React.useState({
    employeeId: props.selectedObject.id,
    id: '',
    addressLine1: '',
    addressLine2: '',
    addressLine3: '',
    country: '',
    state: '',
    city: '',
    areaCode: 0,
    lattitude: 0,
    longitude: 0,
    radius: 0,
    timeZone: '',
    dayLightSaving: true,
    isDefault: true,
    addressTypeId: '',
    userId: '',
    siteId: '',
  });

  useEffect(() => {
    dispatch(getPersonAddress(state.employeeId));
  }, []);

  useEffect(() => {
    if (!CommonUtil.isEmpty(store.address.addressList)) {
      if (!CommonUtil.isEmpty(store.address.addressList.address[0])) {
        setState(store.address.addressList.address[0]);
      }
    }
    setCities(cityData);
    setCountries(countryData);
    setSelectStates(stateData);
    setTimeZones(timeZoneData);
  }, [store.address.addressList]);

  useEffect(() => {
    dispatch(
      getAddressTypes(0, 0, false, (data) => {
        setAddressTypes(data.addressType);
      })
    );
  }, []);
  const handleClose = () => {
    props.onClose(false);
  };
  const handleChange = (event) => {
    const name = event.target.name;
    setState({
      ...state,
      [name]: event.target.value,
    });

    setError({
      [name]: false,
    });
  };

  const [error, setError] = React.useState({
    deptName: false,
    deptCode: false,
    parentDept: false,
    description: false,
  });
  const [emails, SetEmails] = React.useState([]);

  const handleSubmit = (event) => {
    event.preventDefault();
    if (isFormValid(state)) {
      dispatch(
        updateAddress(state, (data) => {
          handleError(data);
        })
      );
    }
  };
  const handleError = (data) => {
    switch (data.code) {
      case 'LMSE0037':
        setError({ city: true });
        break;
    }

    if (data.code === 'LMSI0009' || data.code === 'LMSI0010') {
      toast.success(data.message);
      handleClose();
    } else {
      toast.error(data.message);
      // handleClose();
    }
  };

  const isFormValid = (event) => {
    if (CommonUtil.isEmptyString(state.addressLine1)) {
      setError({ addressLine1: true });
      toast.error(t('ADD014'));
      return false;
    }
    if (CommonUtil.isEmptyString(state.city)) {
      setError({ city: true });
      toast.error(t('ADD015'));
      return false;
    }
    if (CommonUtil.isEmptyString(state.addressTypeId)) {
      setError({ addressTypeId: true });
      toast.error(t('ADD016'));
      return false;
    }
    return true;
  };

  return (
    <>
      <form noValidate autoComplete='off'>
        <Grid container justifyContent='center' alignItems='center'>
          <Grid item xs={12} sm={6}>
            <Box display='flex' justifyContent='center' m={1} p={1}>
              <TextField
                fullWidth
                error={error.addressLine1}
                name='addressLine1'
                label={t('ADD001')}
                helperText=''
                variant='outlined'
                size='small'
                value={state.addressLine1}
                defaultValue={state.addressLine1}
                onChange={handleChange}
              />
            </Box>
          </Grid>

          <Grid item xs={12} sm={6}>
            <Box display='flex' justifyContent='center' m={1} p={1}>
              <TextField
                fullWidth
                error={error.addressLine2}
                name='addressLine2'
                label={t('ADD002')}
                helperText=''
                variant='outlined'
                size='small'
                value={state.addressLine2}
                defaultValue={state.addressLine2}
                onChange={handleChange}
              />
            </Box>
          </Grid>

          <Grid item xs={12} sm={6}>
            <Box display='flex' justifyContent='center' m={1} p={1}>
              <TextField
                fullWidth
                error={error.addressLine3}
                name='addressLine3'
                label={t('Address Line3')}
                helperText=''
                variant='outlined'
                size='small'
                value={state.addressLine3}
                defaultValue={state.addressLine3}
                onChange={handleChange}
              />
            </Box>
          </Grid>

          <Grid item xs={12} sm={6}>
            <Box display='flex' justifyContent='center' m={1} p={1}>
              <FormControl variant='outlined' size='small' fullWidth>
                <InputLabel>{t('ADD003')}</InputLabel>
                <Select label={t('ADD003')} error={error.country} name='country' value={state.country} defaultValue={state.country} onChange={handleChange}>
                  <MenuItem value='' selected>
                    <em>None</em>
                  </MenuItem>
                  {countries ? (
                    countries.map((data, index) => (
                      <MenuItem key={index} value={data.id} style={{ maxWidth: '230px' }}>
                        <Typography variant='inherit' noWrap>
                          {data.name}
                        </Typography>
                      </MenuItem>
                    ))
                  ) : (
                    <></>
                  )}
                </Select>
              </FormControl>
            </Box>
          </Grid>

          <Grid item xs={12} sm={6}>
            <Box display='flex' justifyContent='center' m={1} p={1}>
              <FormControl variant='outlined' size='small' fullWidth>
                <InputLabel>{t('ADD004')}</InputLabel>
                <Select label={t('ADD004')} error={error.state} name='state' value={state.state} defaultValue={state.state} onChange={handleChange}>
                  <MenuItem value='' selected>
                    <em>None</em>
                  </MenuItem>
                  {selectStates ? (
                    selectStates.map((data, index) => (
                      <MenuItem key={index} value={data.id} style={{ maxWidth: '230px' }}>
                        <Typography variant='inherit' noWrap>
                          {data.name}
                        </Typography>
                      </MenuItem>
                    ))
                  ) : (
                    <></>
                  )}
                </Select>
              </FormControl>
            </Box>
          </Grid>

          <Grid item xs={12} sm={6}>
            <Box display='flex' justifyContent='center' m={1} p={1}>
              <FormControl variant='outlined' size='small' fullWidth>
                <InputLabel>{t('ADD005')}</InputLabel>
                <Select label={t('ADD005')} error={error.city} name='city' value={state.city} defaultValue={state.city} onChange={handleChange}>
                  <MenuItem value='' selected>
                    <em>None</em>
                  </MenuItem>
                  {cities ? (
                    cities.map((data, index) => (
                      <MenuItem key={index} value={data.id} style={{ maxWidth: '230px' }}>
                        <Typography variant='inherit' noWrap>
                          {data.name}
                        </Typography>
                      </MenuItem>
                    ))
                  ) : (
                    <></>
                  )}
                </Select>
              </FormControl>
            </Box>
          </Grid>

          <Grid item xs={12} sm={6}>
            <Box display='flex' justifyContent='center' m={1} p={1}>
              <TextField
                fullWidth
                error={error.areaCode}
                name='areaCode'
                label={t('ADD006')}
                helperText=''
                variant='outlined'
                size='small'
                value={state.areaCode}
                defaultValue={state.areaCode}
                onChange={handleChange}
              />
            </Box>
          </Grid>

          <Grid item xs={12} sm={6}>
            <Box display='flex' justifyContent='center' m={1} p={1}>
              <TextField
                fullWidth
                error={error.lattitude}
                name='lattitude'
                label={t('ADD007')}
                helperText=''
                variant='outlined'
                size='small'
                value={state.lattitude}
                defaultValue={state.lattitude}
                onChange={handleChange}
              />
            </Box>
          </Grid>

          <Grid item xs={12} sm={6}>
            <Box display='flex' justifyContent='center' m={1} p={1}>
              <TextField
                fullWidth
                error={error.longitude}
                name='longitude'
                label={t('ADD008')}
                helperText=''
                variant='outlined'
                size='small'
                value={state.longitude}
                defaultValue={state.longitude}
                onChange={handleChange}
              />
            </Box>
          </Grid>

          <Grid item xs={12} sm={6}>
            <Box display='flex' justifyContent='center' m={1} p={1}>
              <TextField
                fullWidth
                error={error.radius}
                name='radius'
                label={t('ADD009')}
                helperText=''
                variant='outlined'
                size='small'
                value={state.radius}
                defaultValue={state.radius}
                onChange={handleChange}
              />
            </Box>
          </Grid>

          <Grid item xs={12} sm={6}>
            <Box display='flex' justifyContent='center' m={1} p={1}>
              <FormControl variant='outlined' size='small' fullWidth>
                <InputLabel>{t('ADD010')}</InputLabel>
                <Select label={t('ADD010')} error={error.timeZone} name='timeZone' value={state.timeZone} defaultValue={state.timeZone} onChange={handleChange}>
                  <MenuItem value='' selected>
                    <em>None</em>
                  </MenuItem>
                  {timeZones ? (
                    timeZones.map((data, index) => (
                      <MenuItem key={index} value={data.id} style={{ maxWidth: '230px' }}>
                        <Typography variant='inherit' noWrap>
                          {data.name}
                        </Typography>
                      </MenuItem>
                    ))
                  ) : (
                    <></>
                  )}
                </Select>
              </FormControl>
            </Box>
          </Grid>

          <Grid item xs={12} sm={6}>
            <Box display='flex' justifyContent='center' m={1} p={1}>
              <FormControl variant='outlined' size='small' fullWidth>
                <InputLabel>{t('ATYPE001')}</InputLabel>
                <Select
                  label={t('ATYPE001')}
                  error={error.addressTypeId}
                  name='addressTypeId'
                  value={state.addressTypeId}
                  defaultValue={state.addressTypeId}
                  onChange={handleChange}
                >
                  <MenuItem value='' selected>
                    <em>None</em>
                  </MenuItem>
                  {addressTypes ? (
                    addressTypes.map((data, index) => (
                      <MenuItem key={data.id} value={data.id} style={{ maxWidth: '230px' }}>
                        <Typography variant='inherit' noWrap>
                          {data.name}
                        </Typography>
                      </MenuItem>
                    ))
                  ) : (
                    <></>
                  )}
                </Select>
              </FormControl>
            </Box>
          </Grid>
        </Grid>
        <DialogActions>
          <SubmitButton onClick={handleSubmit} color='primary' variant='contained' size='small'>
            {t('COMMON035')}
          </SubmitButton>
          <CancelButton color='primary' onClick={handleClose} variant='contained' size='small'>
            {t('COMMON008')}
          </CancelButton>
        </DialogActions>
      </form>
    </>
  );
}
