import React, { Component } from "react";
import { BrowserRouter } from 'react-router-dom';
import store from './config/configureStore';
import { Provider } from 'react-redux';
import Routes from './Routes';
import ScrollToTop from './utils/ScrollToTop';
import './assets/base.scss';
import CssBaseline from '@material-ui/core/CssBaseline';
import BrowserLanguage from "./components/Util/BrowserLanguage";
import i18n from "i18next";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
toast.configure()
class App extends Component {

   componentDidMount() {
    let language = BrowserLanguage.getDefaultLanguage();
    language = language === null?'en':language;
    i18n.changeLanguage(language);
  }

  render() {
    return (
      <Provider store={store}>
        <BrowserRouter basename="/">
          <CssBaseline />
          <ScrollToTop>
            <Routes />
            <ToastContainer
              autoClose={2000}
              position="top-center"
              className="toast-container"
              toastClassName="dark-toast"
            />
          </ScrollToTop>
        </BrowserRouter>
      </Provider>
    );
  }
}

export default App;
