import React from 'react';
import { ListItemIcon, ListItemText, Toolbar } from '@material-ui/core';
import { Menu, MenuItem } from '@material-ui/core';
import { IconButton } from '@material-ui/core';
import LockIcon from '@material-ui/icons/Lock';
import AccountCircleRoundedIcon from '@material-ui/icons/AccountCircleRounded';
import { ReactComponent as DownArrow } from '../../assets/cloudaccess-svg/Path1875.svg';
import Tooltip from '@material-ui/core/Tooltip';
import { setHeaderToken } from '../../security-config/LoginActions';
import { REACT_URI_LANDING } from '../../actions/EndPoints';

class HeaderUser extends React.Component {
  state = {
    anchorEl: null,
  };

  handleMenuOpen = (event) => {
    this.setState({
      menuAnchor: event.target,
    });
  };

  handleMenuClose = () => {
    this.setState({
      menuAnchor: null,
    });
  };

  logout() {
    localStorage.removeItem('ormToken');
    setHeaderToken(false);
    window.location.href = REACT_URI_LANDING;
  }

  render() {
    const { menuAnchor } = this.state;
    const isMenuOpen = Boolean(menuAnchor);

    return (
      <div>
        <Toolbar>
          <Tooltip title='Account' arrow>
            <IconButton color='inherit' onClick={this.handleMenuOpen}>
              <AccountCircleRoundedIcon className='header-account-icon' style={{}} />
              <DownArrow style={{ margin: '5px' }} />
            </IconButton>
          </Tooltip>
        </Toolbar>
        <Menu
          anchorEl={menuAnchor}
          anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
          transformOrigin={{ vertical: 'top', horizontal: 'right' }}
          open={isMenuOpen}
          onClose={this.handleMenuClose}
          PaperProps={{
            style: {
              width: 200,
            },
          }}
        >
          {/* <MenuItem onClick={this.handleMenuClose}>
            <ListItemIcon>
              <SettingsIcon />
            </ListItemIcon>
            <ListItemText primary="Account" />
          </MenuItem> */}

          <MenuItem onClick={this.logout}>
            <ListItemIcon>
              <LockIcon />
            </ListItemIcon>
            <ListItemText primary='Logout' />
          </MenuItem>
        </Menu>
      </div>
    );
  }
}

export default HeaderUser;
