import axios from 'axios';

import { JAVA_API_DESIGNATION, JAVA_API_DESIGNATION_SEARCH } from './ApiEndPoints';
import { DESIGNATION_LIST } from './types';

export const getDesignations = (pageNumber, pageSize, isPageable, callBack) => async (dispatch) => {
  try {
    const res = await axios.get(JAVA_API_DESIGNATION + '?page=' + pageNumber + '&size=' + pageSize + '&isPageable=' + isPageable);
    if (res.status === 200 && res.data.code === 'OMSI0000') {
      if (isPageable) {
        dispatch({
          type: DESIGNATION_LIST,
          payload: res.data.data,
        });
      } else {
        callBack(res.data.data);
      }
    } else {
      if (isPageable) {
        dispatch({
          type: DESIGNATION_LIST,
          payload: [],
        });
      }
    }
  } catch {}
};

export const filterDesignation = (name, code, page, size) => async (dispatch) => {
  try {
    let query = '';
    if (name !== '') {
      query += '&name=' + name;
    }
    if (code !== '') {
      query += '&code=' + code;
    }
    const res = await axios.get(JAVA_API_DESIGNATION_SEARCH + '?page=' + page + '&size=' + size + query);
    if (res.status === 200 && res.data.code === 'OMSI0000') {
      dispatch({
        type: DESIGNATION_LIST,
        payload: res.data.data,
      });
    } else {
      dispatch({
        type: DESIGNATION_LIST,
        payload: [],
      });
    }
  } catch {}
};

export const updateDesignation = (payload, callBack) => async (dispatch) => {
  try {
    let isAddMode = payload.id === '' ? true : false;

    let res = '';
    if (isAddMode) {
      res = await axios.post(JAVA_API_DESIGNATION, [payload]);
    } else {
      res = await axios.put(JAVA_API_DESIGNATION + '/' + payload.id, payload);
    }
    callBack(res.data);
  } catch {}
};

export const deleteDesignation = (ids, callBack) => async (dispatch) => {
  try {
    const deleteObj = {};
    deleteObj.idsOrCodes = ids;
    deleteObj.deleteChild = false;

    const res = await axios.delete(JAVA_API_DESIGNATION, { data: deleteObj });
    callBack(res.data);
  } catch {}
};
