import { JAVA_API_COMPANY, JAVA_API_LOGIN } from '../actions/ApiEndPoints';
import axios from 'axios';
import { SET_CURRENT_USER } from '../actions/types';
import jwt_decode from 'jwt-decode';

export const getAccessToken = (payload, callBack) => async (dispatch) => {
  try {
    localStorage.removeItem('ormToken');
    localStorage.removeItem('tempOrmToken');

    const res = await axios.post(JAVA_API_LOGIN, payload);
    if (res.data.code !== 'UASI0011' && res.data.code !== 'UASI0000') {
      callBack(res.data);
    } else {
      if (res.data.code === 'UASI0000') {
        const accessToken = res.data.data.access_token;
        const decoded = jwt_decode(accessToken);
        localStorage.setItem('tempOrmToken', accessToken);
        dispatch({
          type: SET_CURRENT_USER,
          payload: decoded,
        });
      } else {
        const accessToken = res.data.data.access_token;
        const refreshToken = res.data.data.refresh_token;

        const decoded = jwt_decode(accessToken);
        localStorage.setItem('ormToken', accessToken);
        localStorage.setItem('ormRToken', refreshToken);
        setHeaderToken(accessToken);
        dispatch({
          type: SET_CURRENT_USER,
          payload: decoded,
        });
      }

      callBack(res.data);
    }
  } catch {}
};

export const logout = () => (dispatch) => {
  localStorage.removeItem('ormToken');
  setHeaderToken(false);
  dispatch({
    type: SET_CURRENT_USER,
    payload: {},
  });
};

export function setHeaderToken(token) {
  if (token) {
    axios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
    axios.defaults.headers.common['companyId'] = localStorage.getItem('companyId');
  } else {
    delete axios.defaults.headers.common['Authorization'];
  }
}

// export const VerifyCompany = (code, callBack) => async (dispatch) => {
//   const payload = {
//     companyCode: code,
//     companyId: '',
//   };
//   const res = await axios
//     .put(JAVA_API_COMPANY, payload)
//     .then(function(res) {
//       if (res.data.code === 'UASI0011') {
//         const accessToken = res.data.data.access_token;
//         const decoded = jwt_decode(accessToken);
//         localStorage.setItem('ormToken', accessToken);
//         dispatch({
//           type: SET_CURRENT_USER,
//           payload: decoded,
//         });
//       }
//       callBack(res.data);
//     })
//     .catch(function(error) {
//     });
// };
