import React, { useEffect } from 'react';
import { InputLabel } from '@material-ui/core';
import { Grid, Box, MenuItem, Select, Typography, FormControl, TextField } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import SubmitButton from '../../components/SubmitButton';
import CommonUtil from '../../components/Util/CommonUtils';
import { toast } from 'react-toastify';
import { getDepartments } from '../../actions/DepartmentApiCalls';
import { getDesignations } from '../../actions/DesignationApiCalls';
import { DialogActions } from '@material-ui/core';
import CancelButton from '../../components/CancelButton';
import { REACT_URI_PERSON } from '../../actions/EndPoints';
import { updatePerson } from '../../actions/PersonApiCalls';
import { useHistory } from 'react-router';

export default function EditPerson(props) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [departments, setDepartments] = React.useState([]);
  const [designations, setDesignations] = React.useState([]);
  const [error, setError] = React.useState({});
  let history = useHistory();

  const [state, setState] = React.useState({
    id: props.selectedObject.id,
    firstName: props.selectedObject.firstName,
    lastName: props.selectedObject.lastName,
    email: props.selectedObject.email,
    phone: props.selectedObject.phone,
    employeeCode: props.selectedObject.employeeCode,
    joinDate: props.selectedObject.joinDate,
    gender: props.selectedObject.gender,
    departmentId: props.selectedObject.departmentId,
    designationId: props.selectedObject.designationId,
    profilePhoto: props.selectedObject.profilePhoto,
  });

  const handleClose = () => {
    props.onClose();
    // props.loadPersons();
  };

  const handleChange = (event) => {
    const name = event.target.name;
    setState({
      ...state,
      [name]: event.target.value,
    });

    setError({
      [name]: false,
    });
  };

  const uploadImage = async (e) => {
    const file = e.target.files[0];
    const base64Data = await convertBase64(file);
    setState({
      ...state,
      profilePhoto: base64Data,
    });
  };

  const convertBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);

      fileReader.onload = () => {
        resolve(fileReader.result);
      };

      fileReader.onerror = (error) => {
        reject(error);
      };
    });
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    if (isFormValid(state)) {
      dispatch(
        updatePerson(state, (data) => {
          handleError(data);
        })
      );
    }
  };

  const isFormValid = (event) => {
    if (CommonUtil.isEmptyString(state.firstName)) {
      setError({ firstName: true });
      toast.error(t('PERSON016'));
      return false;
    }
    if (!CommonUtil.isValidNumericName(state.firstName)) {
      setError({ firstName: true });
      toast.error(t('PERSON017'));
      return false;
    }

    if (!CommonUtil.isValidNumericName(state.lastName)) {
      setError({ lastName: true });
      toast.error(t('PERSON018'));
      return false;
    }

    // if (CommonUtil.isEmptyString(state.email) && CommonUtil.isEmptyString(state.phone)) {
    //   setError({ email: true });
    //   toast.error(t('PERSON021'));
    //   return false;
    // }

    if (!CommonUtil.isValidEmail(state.email)) {
      setError({ email: true });
      toast.error(t('PERSON022'));
      return false;
    }

    if (!CommonUtil.isValidMobile(state.phone)) {
      setError({ phone: true });
      toast.error(t('PERSON023'));
      return false;
    }

    if (CommonUtil.isEmptyString(state.employeeCode)) {
      setError({ employeeCode: true });
      toast.error(t('PERSON019'));
      return false;
    }

    if (!CommonUtil.isValidCode(state.employeeCode)) {
      setError({ employeeCode: true });
      toast.error(t('PERSON020'));
      return false;
    }

    if (CommonUtil.isEmptyString(state.gender)) {
      setError({ gender: true });
      toast.error(t('PERSON025'));
      return false;
    }
    return true;
  };

  const handleError = (data) => {
    switch (data.code) {
      case 'OMSI0006':
        history.push(REACT_URI_PERSON);
        break;
      case 'OMSE0097':
        setError({ employeeCode: true });
        break;
      case 'OMSE0184':
        setError({ email: true });
        break;
      case 'OMSE0213':
        setError({ phone: true });
        break;
      case 'OMSE0214':
        setError({ phone: true });
        break;
      case 'OMSE0160':
        setError({ email: true });
        break;
      default:
        break;
    }

    if (data.code === 'OMSI0006' || data.code === 'OMSI0007') {
      toast.success(data.message);
      handleClose();
    } else {
      toast.error(data.message);
    }
  };

  useEffect(() => {
    dispatch(
      getDepartments(0, 0, false, (data) => {
        setDepartments(data.department);
      })
    );
  }, []);

  useEffect(() => {
    dispatch(
      getDesignations(0, 0, false, (data) => {
        setDesignations(data.designation);
      })
    );
  }, []);

  return (
    <>
      <form noValidate autoComplete='off'>
        <Grid container justifyContent='center' alignItems='center'>
          <Grid item xs={12} sm={6}>
            <Box display='flex' justifyContent='center' m={1} p={1}>
              <TextField
                fullWidth
                required
                error={error.firstName}
                name='firstName'
                label={t('PERSON003')}
                variant='outlined'
                size='small'
                defaultValue={state.firstName}
                onChange={handleChange}
              />
            </Box>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Box display='flex' justifyContent='center' m={1} p={1}>
              <TextField
                fullWidth
                error={error.lastName}
                name='lastName'
                label={t('PERSON004')}
                variant='outlined'
                size='small'
                defaultValue={state.lastName}
                onChange={handleChange}
              />
            </Box>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Box display='flex' justifyContent='center' m={1} p={1}>
              <TextField fullWidth error={error.email} name='email' label={t('PERSON005')} variant='outlined' size='small' defaultValue={state.email} onChange={handleChange} />
            </Box>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Box display='flex' justifyContent='center' m={1} p={1}>
              <TextField fullWidth error={error.phone} name='phone' label={t('PERSON006')} variant='outlined' size='small' defaultValue={state.phone} onChange={handleChange} />
            </Box>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Box display='flex' justifyContent='center' m={1} p={1}>
              <TextField
                fullWidth
                required
                error={error.employeeCode}
                name='employeeCode'
                label={t('PERSON008')}
                variant='outlined'
                size='small'
                defaultValue={state.employeeCode}
                onChange={handleChange}
              />
            </Box>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Box display='flex' justifyContent='center' m={1} p={1}>
              <FormControl variant='outlined' size='small' fullWidth>
                <InputLabel>{t('PERSON007')}</InputLabel>
                <Select
                  fullWidth
                  error={error.gender}
                  name='gender'
                  label={t('PERSON007')}
                  variant='outlined'
                  size='small'
                  value={state.gender}
                  defaultValue={state.gender}
                  onChange={handleChange}
                >
                  <MenuItem value={'M'}>Male</MenuItem>
                  <MenuItem value={'F'}>Female</MenuItem>
                  <MenuItem value={'O'}>Others</MenuItem>
                </Select>
              </FormControl>
            </Box>
          </Grid>

          <Grid item xs={12} sm={6}>
            <Box display='flex' justifyContent='center' m={1} p={1}>
              <FormControl variant='outlined' size='small' fullWidth>
                <InputLabel>{t('PERSON010')}</InputLabel>
                <Select
                  fullWidth
                  error={error.departmentId}
                  name='departmentId'
                  label={t('PERSON010')}
                  variant='outlined'
                  size='small'
                  value={state.departmentId}
                  defaultValue={state.departmentId}
                  onChange={handleChange}
                >
                  <MenuItem value='' selected>
                    <em>None</em>
                  </MenuItem>
                  {departments ? (
                    departments.map((data, index) => (
                      <MenuItem key={index} value={data.id} style={{ maxWidth: '230px' }}>
                        <Typography variant='inherit' noWrap>
                          {data.name}
                        </Typography>
                      </MenuItem>
                    ))
                  ) : (
                    <></>
                  )}
                </Select>
              </FormControl>
            </Box>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Box display='flex' justifyContent='center' m={1} p={1}>
              <FormControl variant='outlined' size='small' fullWidth>
                <InputLabel>{t('PERSON011')}</InputLabel>
                <Select
                  fullWidth
                  error={error.designationId}
                  name='designationId'
                  label={t('PERSON011')}
                  variant='outlined'
                  size='small'
                  value={state.designationId}
                  defaultValue={state.designationId}
                  onChange={handleChange}
                >
                  <MenuItem value='' selected>
                    <em>None</em>
                  </MenuItem>
                  {designations ? (
                    designations.map((data, index) => (
                      <MenuItem key={index} value={data.id} style={{ maxWidth: '230px' }}>
                        <Typography variant='inherit' noWrap>
                          {data.name}
                        </Typography>
                      </MenuItem>
                    ))
                  ) : (
                    <></>
                  )}
                </Select>
              </FormControl>
            </Box>
          </Grid>
        </Grid>
        <DialogActions>
          <SubmitButton onClick={handleSubmit} color='primary' variant='contained' size='small'>
            {t('COMMON035')}
          </SubmitButton>
          <CancelButton color='primary' onClick={handleClose} variant='contained' size='small'>
            {t('COMMON008')}
          </CancelButton>
        </DialogActions>
      </form>
    </>
  );
}
