import React, { Fragment } from 'react';
import clsx from 'clsx';
import { Link } from 'react-router-dom';
import { Box } from '@material-ui/core';
import { REACT_URI_DASHBOARD } from '../../actions/EndPoints';
import { ReactComponent as LogoIcon } from '../../assets/cloudaccess-svg/access-cloud-white.svg';

const HeaderLogo = () => {
  return (
    <Fragment>
      <div className={clsx('app-header-logo', {})}>
        <Box title='Organization Management'>
          <Link to={REACT_URI_DASHBOARD} className='header-logo-wrapper-link'>
            <LogoIcon className='header-logo' />
          </Link>
        </Box>
      </div>
    </Fragment>
  );
};

export default HeaderLogo;
