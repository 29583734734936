import { Box, Grid, IconButton } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import DataTable from '../../components/DataTable';
import { useTranslation } from 'react-i18next';
import Toolbar from '../../components/Toolbar';
import { useDispatch, useSelector } from 'react-redux';
import { getDepartments, deleteDepartment } from '../../actions/DepartmentApiCalls';
import AddDepartment from './AddDepartment';
import ConfirmModal from '../../components/ConfirmModal';
import { toast } from 'react-toastify';
import { confirmAlert } from 'react-confirm-alert';
import FilterDepartment from './FilterDepartment';
import Pagination from '../../components/Pagination';
import CommonUtil from '../../components/Util/CommonUtils';
import { Tooltip, Fab } from '@material-ui/core';
import Breadcrumb from '../../components/Breadcrumb';
import { ReactComponent as Add } from '../../assets/icons/Add.svg';
import { ReactComponent as Edit } from '../../assets/icons/Edit.svg';
import { ReactComponent as Delete } from '../../assets/icons/Delete.svg';
import { ReactComponent as Refresh } from '../../assets/icons/Refresh.svg';

export default function Department() {
  const dispatch = useDispatch();
  const store = useSelector((state) => state);
  const { t } = useTranslation();
  const [data, setData] = useState([]);
  const [selectedData, onSelectionChange] = useState([]);
  const [openEditDialog, setOpenEditDialog] = useState(false);
  const [updateObject, setUpdateObject] = useState({});

  const [page, setPage] = React.useState(0);
  const [totalRecords, setTotalRecords] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const [state, setState] = React.useState({
    id: '',
    name: '',
    code: '',
    parentDepartment: '',
    description: '',
  });

  useEffect((props) => {
    loadDepartments();
  }, []);

  useEffect(() => {
    if (!CommonUtil.isEmpty(store.department.departmentList)) {
      setTotalRecords(store.department.departmentList.totalCount);
      setPage(store.department.departmentList.currentPage - 1);
    } else {
      setTotalRecords(0);
      setPage(0);
    }
    setData(store.department.departmentList.department);
  }, [store.department.departmentList]);

  const loadDepartments = () => {
    dispatch(getDepartments(page + 1, rowsPerPage, true));
  };

  const handleChangePage = (newPage) => {
    setPage(newPage);
    dispatch(getDepartments(newPage + 1, rowsPerPage, true));
  };

  const handleChangeRowsPerPage = (pageSize) => {
    setRowsPerPage(pageSize);
    setPage(0);
    dispatch(getDepartments(1, pageSize, true));
  };

  const openAddDepartmentDialog = () => {
    setUpdateObject(state);
    setOpenEditDialog(true);
  };

  const deleteRecord = (data) => {
    if (data.length < 1) {
      toast.warning(t('COMMON019'));
      return;
    }
    var idsToDelete = [];
    data.map((record, index) => {
      idsToDelete[index] = record.id;
    });
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <ConfirmModal
            open={true}
            text={t('COMMON018')}
            onConfirm={() => {
              dispatch(
                deleteDepartment(idsToDelete, (data) => {
                  handlError(data);
                })
              );
              onClose();
            }}
            onClose={() => onClose()}
          />
        );
      },
    });
  };

  const handlError = (data) => {
    switch (data.code) {
      case 'OMSI0073':
        toast.success(data.message);
        loadDepartments();
        break;
      default:
        toast.error(data.message);
        break;
    }
  };

  const breadcrumb = [{ path: '', name: t('DEPT001') }];

  const columns = [
    {
      field: 'name',
      title: t('COMMON044'),
      headerStyle: {
        color: 'black',
        backgroundColor: 'transparent',
      },
    },
    { field: 'code', title: t('COMMON045'), editable: 'never' },
    {
      field: 'parentDepartment.name',
      title: t('COMMON050'),
      editable: 'never',
    },
    { field: 'description', title: t('DEPT009'), editable: 'never' },
  ];

  const options = { actionsColumnIndex: -1, search: false, selection: true, toolbar: false, paging: true, minBodyHeight: '40vh', initialPage: 1, pageSize: rowsPerPage };

  const actions = [
    {
      icon: () => <Edit color='secondary' />,
      tooltip: t('COMMON005'),
      position: 'row',
      onClick: (event, rowData) => {
        setUpdateObject(rowData);
        setOpenEditDialog(true);
      },
    },
    {
      icon: () => <Delete color='secondary' />,
      tooltip: t('COMMON004'),
      position: 'row',
      onClick: (event, rowData) => {
        deleteRecord([rowData]);
      },
    },
  ];

  return (
    <Grid>
      <Breadcrumb links={breadcrumb}>
        <Tooltip title={t('COMMON009')}>
          <IconButton color='secondary' aria-label={t('COMMON009')} onClick={openAddDepartmentDialog}>
            <Add style={{ height: 35, width: 35 }} />
          </IconButton>
        </Tooltip>
      </Breadcrumb>

      <Toolbar>
        <Box p={1}>
          <Tooltip title={t('COMMON002')}>
            <Refresh onClick={loadDepartments} />
          </Tooltip>
        </Box>
        <Box p={1} flexGrow={1}>
          <Tooltip title={t('COMMON004')}>
            <Delete onClick={() => deleteRecord(selectedData)} />
          </Tooltip>
        </Box>
        <Box p={1}>
          <FilterDepartment props={state} />
        </Box>
      </Toolbar>

      <DataTable
        columns={columns}
        data={data}
        actions={actions}
        onSelectedRoles={(rows) => onSelectionChange(rows)}
        options={options}
        count={totalRecords}
        page={page}
        onChangePage={(newPage) => handleChangePage(newPage)}
        rowsPerPage={rowsPerPage}
        onChangeRowsPerPage={(pageSize) => handleChangeRowsPerPage(pageSize)}
      />

      {openEditDialog && (
        <AddDepartment
          selectedObject={updateObject}
          open={openEditDialog}
          onClose={() => {
            setOpenEditDialog(false);
            loadDepartments();
          }}
        />
      )}
    </Grid>
  );
}
