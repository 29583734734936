import React from 'react';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Box, Grid } from '@material-ui/core';
import { filterSiteType } from '../../actions/SiteTypeApiCalls';
import { toast } from 'react-toastify';
import SubmitButton from '../../components/SubmitButton';
import CancelButton from '../../components/CancelButton';
import FilterListIcon from '@material-ui/icons/FilterList';
import { Tooltip } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { ReactComponent as Cancel } from '../../assets/icons/Cancel.svg';

export default function FilterAddressType() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  // eslint-disable-next-line
  const store = useSelector(state => state);
  const [open, setOpen] = React.useState(false);

  const [page] = React.useState(0);
  const [rowsPerPage] = React.useState(10);
  const useStyles = makeStyles(theme => ({
    absolute: {
      margin: theme.spacing(),
      position: 'absolute',
      right: theme.spacing(5),
    },
  }));
  const classes = useStyles();
  const [state, setState] = React.useState({
    name: '',
    code: '',
  });

  const handleClose = () => {
    setState({
      name: '',
      code: '',
    });
    setOpen(false);
  };

  const reset = () => {
    setState({
      name: '',
      code: '',
    });
  };

  const handleChange = event => {
    const name = event.target.name;
    setState({
      ...state,
      [name]: event.target.value,
    });
  };

  const loadData = () => {
    dispatch(filterSiteType(state.name, state.code, page, rowsPerPage));
  };

  const handleSubmit = () => {
    if (state.name.trim() === '' && state.code.trim() === '') {
      toast.error(t('COMMON015'));
      return;
    }
    loadData();
    setState({
      name: '',
      code: '',
    });
    handleClose();
  };

  return (
    <div>
      <Tooltip title={t('COMMON006')}>
        <FilterListIcon
          onClick={() => setOpen(true)}
          style={{ height: 35, width: 60 }}
        />
      </Tooltip>
      <Dialog
        maxWidth="sm"
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
        className="custom-filter-modal"
      >
        <DialogTitle id="form-dialog-title">
          <Box display="flex">
            <Box flexGrow={1}>{t('COMMON006')}</Box>
            <Cancel onClick={handleClose} style={{ height: 25, width: 25 }} />
          </Box>
        </DialogTitle>
        <DialogContent>
          <form noValidate autoComplete="off">
            <Grid container justify="center" alignItems="center">
              <Grid item xs={12} sm={6}>
                <Box display="flex" justifyContent="center" m={1} p={1}>
                  <TextField
                    fullWidth
                    name="name"
                    label={t('COMMON044')}
                    helperText=""
                    variant="outlined"
                    size="small"
                    onChange={handleChange}
                    value={state.name}
                  />
                </Box>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Box display="flex" justifyContent="center" m={1} p={1}>
                  <TextField
                    fullWidth
                    name="code"
                    label={t('COMMON045')}
                    helperText=""
                    variant="outlined"
                    size="small"
                    onChange={handleChange}
                    value={state.code}
                  />
                </Box>
              </Grid>
            </Grid>
          </form>
        </DialogContent>
        <DialogActions>
          <SubmitButton
            onClick={handleSubmit}
            color="primary"
            variant="contained"
            size="small"
          >
            {t('COMMON007')}
          </SubmitButton>
          <CancelButton
            onClick={reset}
            variant="contained"
            size="small"
            color="orange"
            style={{
              color: 'white',
              background: 'orange',
            }}
          >
            {t('COMMON025')}
          </CancelButton>
          <CancelButton
            onClick={handleClose}
            color="primary"
            variant="contained"
            size="small"
          >
            {t('COMMON008')}
          </CancelButton>
        </DialogActions>
      </Dialog>
    </div>
  );
}
