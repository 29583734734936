import React, { Fragment, useEffect } from 'react';
import { connect, useSelector } from 'react-redux';

import BarChartIcon from '@material-ui/icons/BarChart';
import CalendarTodayIcon from '@material-ui/icons/CalendarToday';
import ChatIcon from '@material-ui/icons/ChatOutlined';
import CodeIcon from '@material-ui/icons/Code';
import DashboardIcon from '@material-ui/icons/DashboardOutlined';
import ErrorIcon from '@material-ui/icons/ErrorOutline';
import FolderIcon from '@material-ui/icons/FolderOutlined';
import GradeTwoTone from '@material-ui/icons/GradeTwoTone';
import ListAltIcon from '@material-ui/icons/ListAlt';
import LockOpenIcon from '@material-ui/icons/LockOpenOutlined';
import MailIcon from '@material-ui/icons/MailOutlined';
import PresentToAllIcon from '@material-ui/icons/PresentToAll';
import PeopleIcon from '@material-ui/icons/PeopleOutlined';
import PersonIcon from '@material-ui/icons/PersonOutlined';
import ReceiptIcon from '@material-ui/icons/ReceiptOutlined';
import SettingsIcon from '@material-ui/icons/SettingsOutlined';
import ViewModuleIcon from '@material-ui/icons/ViewModule';
import DashboardTwoToneIcon from '@material-ui/icons/DashboardTwoTone';

import {
  REACT_URI_PERSON,
  REACT_URI_DEPARTMENT,
  REACT_URI_DESIGNATION,
  REACT_URI_ADDRESS_TYPE,
  REACT_URI_SITE_TYPE,
  REACT_URI_SITE,
  REACT_URI_ZONE,
  REACT_URI_APPLICATIONS,
} from '../../actions/EndPoints';
import { ReactComponent as SiteManagement } from '../../assets/cloudaccess-svg/SiteManagement.svg';
import { ReactComponent as PersonalManagement } from '../../assets/cloudaccess-svg/Personnel Icon.svg';
import SidebarMenu from '../SidebarMenu';
var iconsMap = {
  BarChartIcon: BarChartIcon,
  CalendarTodayIcon: CalendarTodayIcon,
  ChatIcon: ChatIcon,
  CodeIcon: CodeIcon,
  DashboardIcon: DashboardIcon,
  ErrorIcon: ErrorIcon,
  FolderIcon: FolderIcon,
  DashboardTwoToneIcon: DashboardTwoToneIcon,
  GradeTwoTone: GradeTwoTone,
  ListAltIcon: ListAltIcon,
  LockOpenIcon: LockOpenIcon,
  MailIcon: MailIcon,
  PresentToAllIcon: PresentToAllIcon,
  PeopleIcon: PeopleIcon,
  PersonIcon: PersonIcon,
  ReceiptIcon: ReceiptIcon,
  SettingsIcon: SettingsIcon,
  ViewModuleIcon: ViewModuleIcon,
  SiteManagementIcon: SiteManagement,
  PersonalManagementIcon: PersonalManagement,
  BusinessIcon: DashboardTwoToneIcon,
};

function CustomNavItems(props) {
  const roleCode = props.user.roleCode;

  const navMenuList = [
    {
      label: 'Dashboard',
      content: JSON.parse(
        `
          [
             {
              "label": "Organization",
              "icon": "PersonalManagementIcon",
              "content": [
                {
                  "label": "Person",
                  "description": "",
                  "to": "${REACT_URI_PERSON}"
                },
                {
                  "label": "Department",
                  "description": "",
                  "to": "${REACT_URI_DEPARTMENT}"
                },
                {
                  "label": "Designation",
                  "description": "",
                  "to": "${REACT_URI_DESIGNATION}"
                } ,
                {
                  "label": "Application",
                  "description": "",
                  "to": "${REACT_URI_APPLICATIONS}"
                }  
              ]
            },
            {
              "label": "Site Management",
              "icon": "BusinessIcon",
              "content": [
                {
                  "label": "Address Type",
                  "description": "",
                  "to": "${REACT_URI_ADDRESS_TYPE}"
                },
                {
                  "label": "Site Type",
                  "description": "",
                  "to": "${REACT_URI_SITE_TYPE}"
                },
                {
                  "label": "Site",
                  "description": "",
                  "to": "${REACT_URI_SITE}"
                },
                {
                  "label": "Zone",
                  "description": "",
                  "to": "${REACT_URI_ZONE}"
                }
              ]
            }
          ]
        `,
        (key, value) => {
          if (key === 'icon') {
            return iconsMap[value];
          } else {
            return value;
          }
        }
      ),
    },
  ];

  const employeeNavList = [
    {
      label: 'Dashboard',
      content: JSON.parse(
        `
          [
             {
              "label": "Organization",
              "icon": "PersonalManagementIcon",
              "content": [
                {
                  "label": "Person",
                  "description": "",
                  "to": "${REACT_URI_PERSON}"
                } 
              ]
            }
          ]
        `,
        (key, value) => {
          if (key === 'icon') {
            return iconsMap[value];
          } else {
            return value;
          }
        }
      ),
    },
  ];
  let menuList = navMenuList;
  if (roleCode === 'employee') {
    menuList = employeeNavList;
  }

  return (
    <div>
      {menuList.map((list) => (
        <SidebarMenu component='div' key={list.label} pages={list.content} title={list.label} />
      ))}
    </div>
  );
}

function mapStateToProps(state) {
  return {
    user: state.security.user,
  };
}

export default connect(mapStateToProps)(CustomNavItems);
