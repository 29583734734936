import React from 'react';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import { Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, makeStyles } from '@material-ui/core';
import { getAccessToken } from './LoginActions';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import { useHistory } from 'react-router-dom';
import { REACT_URI_REGISTER_USER, REACT_URI_SWITCH_COMPANY, REACT_URI_DASHBOARD, REACT_URI_FORGOT_PASSWORD } from '../actions/EndPoints';

//
import biozlink from '../assets/images/onboard/access-cloud.png';
import cloudAccess from '../assets/images/onboard/cloudaccess.png';
import iot from '../assets/images/onboard/iot.png';
import PersonIcon from '@material-ui/icons/Person';
import LockIcon from '@material-ui/icons/Lock';
import InputAdornment from '@material-ui/core/InputAdornment';
import IconButton from '@material-ui/core/IconButton';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import './custom.css';
import Link from '@material-ui/core/Link';
import Button from '@material-ui/core/Button';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  form: {
    width: '100%',
    marginTop: theme.spacing(3),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
    backgroundColor: '#1090E5',
    borderRadius: '0.2rem',
    padding: '10px',
    '&:hover': {
      background: '#006fb9',
    },
  },
  container: {
    height: '100%',
    width: '100%',
    justifyContent: 'center',
    alignItems: 'center',
    display: 'flex',
  },
}));

export default function LoginPage(props) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  let history = useHistory();
  const classes = useStyles();

  const [state, setState] = React.useState({
    userName: '',
    password: '',
    companyCode: '',
  });

  const [error, setError] = React.useState({
    userName: false,
    password: false,
    companyCode: false,
  });

  const handleChange = (event) => {
    const name = event.target.name;
    setState({
      ...state,
      [name]: event.target.value,
    });

    setError({
      [name]: false,
    });
  };

  const handleClickShowPassword = () => {
    setState({ ...state, showPassword: !state.showPassword });
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    dispatch(
      getAccessToken(state, (data) => {
        handlError(data);
      })
    );
  };

  const handlError = (data) => {
    switch (data.code) {
      case 'UASE0041':
        setError({ userName: true });
        break;
      case 'UASE0010':
        setError({ companyCode: true });
        break;
      case 'UASI0011':
        history.push(REACT_URI_DASHBOARD);
        break;
      case 'UASI0000':
        history.push(REACT_URI_SWITCH_COMPANY);
        break;
      default:
        break;
    }
    if (data.code !== 'UASI0011' && data.code !== 'UASI0000') {
      toast.error(data.message);
    }
  };

  return (
    <Fragment>
      <div className='background-image'>
        <Container component='main' maxWidth='md' className={classes.container}>
          <Grid container className='main_conatiner'>
            <Grid item sm={4} xs={12} className='paper_left'>
              <img src={biozlink} alt='logo' style={{ width: '250px', paddingTop: '50px' }} />
              <img src={cloudAccess} alt='logo' className='left_img' />
            </Grid>
            <Grid
              item
              sm={8}
              xs={12}
              className='paper_right'
              style={{
                justifyContent: 'center',
                alignItems: 'center',
                textAlign: 'center',
              }}
            >
              <Grid container spacing={5} alignItems='center'>
                <Grid item></Grid>
                <Grid item xs>
                  <Typography className='signin_title' variant='h5' color='textPrimary' align='center'>
                    Sign In
                  </Typography>
                </Grid>
              </Grid>
              <form className={classes.form} noValidate onSubmit={handleSubmit} autoComplete='off'>
                <Grid container alignItems='center'>
                  <Grid item>
                    <PersonIcon className='login_icons' />
                  </Grid>
                  <Grid item xs>
                    <TextField
                      error={error.userName}
                      className='login_input'
                      size='small'
                      variant='outlined'
                      margin='normal'
                      required
                      fullWidth
                      id='userName'
                      label={t('COMMON046')}
                      autoComplete='userName'
                      autoFocus
                      name='userName'
                      type='text'
                      value={state.userName}
                      onChange={handleChange}
                    />
                  </Grid>
                </Grid>
                <Grid container alignItems='center'>
                  <Grid item>
                    <LockIcon className='login_icons' />
                  </Grid>
                  <Grid item xs>
                    <TextField
                      error={error.password}
                      className='login_input'
                      size='small'
                      variant='outlined'
                      margin='normal'
                      required
                      fullWidth
                      name='password'
                      label={t('COMMON047')}
                      id='password'
                      autoComplete='current-password'
                      type={state.showPassword ? 'text' : 'password'}
                      value={state.password}
                      onChange={handleChange}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position='end'>
                            <IconButton aria-label='toggle password visibility' onClick={handleClickShowPassword} onMouseDown={handleMouseDownPassword} edge='end'>
                              {state.showPassword ? <Visibility className='visibilityIcons' /> : <VisibilityOff className='visibilityIcons' />}
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    />
                  </Grid>
                </Grid>
                <Grid container alignItems='center'>
                  <Grid item></Grid>
                  <Grid item xs>
                    <Box align='right'>
                      <Link href={REACT_URI_FORGOT_PASSWORD} className='link'>
                        {t('forgotPassword')}
                      </Link>
                    </Box>
                  </Grid>
                </Grid>
                <Grid container spacing={5} alignItems='center'>
                  <Grid item></Grid>
                  <Grid item xs>
                    <Button type='submit' fullWidth variant='contained' color='primary' className={classes.submit}>
                      Login
                    </Button>
                  </Grid>
                </Grid>
              </form>

              <Grid container spacing={5} alignItems='center'>
                <Grid item></Grid>
                <Grid item xs>
                  <Box mt={3} className='signIn'>
                    <hr className='hrline_Left' />
                    <Typography variant='body2' color='textPrimary' align='center' style={{ fontSize: '12px' }}>
                      Or sign in with
                    </Typography>
                    <hr className='hrline_Right' />
                  </Box>
                </Grid>
              </Grid>
              <Grid container spacing={5} alignItems='center'>
                <Grid item></Grid>
                <Grid item xs>
                  <Box mt={1} align='center'>
                    {/* onClick={() => dispatch(redirectLogin())} */}
                    <IconButton className='icon_button'>
                      <img src={iot} alt='iotlogo' className='social_icons' />
                    </IconButton>
                  </Box>
                </Grid>
              </Grid>
              <Grid container spacing={5} alignItems='center'>
                <Grid item></Grid>
                <Grid item xs>
                  <Box mt={3} align='center'>
                    <Typography variant='body2' color='textPrimary' align='center' style={{ fontSize: '12px' }}>
                      Don't have an account ?
                    </Typography>
                    <Link href={REACT_URI_REGISTER_USER} className='link'>
                      {t('signUp')}
                    </Link>
                  </Box>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Container>
      </div>
    </Fragment>
  );
}
