import { GET_ERRORS, DEPARTMENT_ERRORS, DESIGNATION_ERRORS, ADDRESS_TYPE_ERRORS, SITE_TYPE_ERRORS, SITE_ERRORS, ZONE_ERRORS, PERSON_ERRORS } from '../actions/types';

const initialState = {
  personError: {},
  departmentError: {},
  designationError: {},
  addressTypeError: {},
  siteTypeError: {},
  siteError: {},
  zoneError: {},
};

export default function(state = initialState, action) {
  switch (action.type) {
    case GET_ERRORS:
      return action.payload;

    case PERSON_ERRORS:
      return {
        ...state,
        personError: action.payload,
      };
    case DEPARTMENT_ERRORS:
      return {
        ...state,
        departmentError: action.payload,
      };
    case DESIGNATION_ERRORS:
      return {
        ...state,
        designationError: action.payload,
      };
    case ADDRESS_TYPE_ERRORS:
      return {
        ...state,
        addressTypeError: action.payload,
      };
    case SITE_TYPE_ERRORS:
      return {
        ...state,
        siteTypeError: action.payload,
      };
    case SITE_ERRORS:
      return {
        ...state,
        siteError: action.payload,
      };
    case ZONE_ERRORS:
      return {
        ...state,
        zoneError: action.payload,
      };

    default:
      return {
        ...initialState,
      };
  }
}
