import axios from 'axios';
import { JAVA_API_SITE, JAVA_API_SITE_SEARCH } from './ApiEndPoints';
import { SITE_LIST } from './types';

export const getSites = (
  pageNumber,
  pageSize,
  isPageable,
  callBack,
) => async dispatch => {
  try {
    const res = await axios.get(
      JAVA_API_SITE +
        '?page=' +
        pageNumber +
        '&size=' +
        pageSize +
        '&isPageable=' +
        isPageable,
    );
    if (res.status === 200 && res.data.code === 'LMSI0000') {
      if (isPageable) {
        dispatch({
          type: SITE_LIST,
          payload: res.data.data,
        });
      } else {
        callBack(res.data.data);
      }
    } else {
      if (isPageable) {
        dispatch({
          type: SITE_LIST,
          payload: [],
        });
      }
    }
  } catch {}
};
export const filterSite = (name, code, page, size) => async dispatch => {
  try {
    let query = '';
    if (name !== '') {
      query += '&name=' + name;
    }
    const res = await axios.get(
      JAVA_API_SITE_SEARCH + '?page=' + page + '&size=' + size + query,
    );
    if (res.status === 200 && res.data.code === 'LMSI0000') {
      dispatch({
        type: SITE_LIST,
        payload: res.data.data,
      });
    } else {
      dispatch({
        type: SITE_LIST,
        payload: [],
      });
    }
  } catch {}
};

export const deleteSites = (ids, callBack) => async dispatch => {
  try {
    const deleteObj = {};
    deleteObj.ids = ids;
    deleteObj.deleteChild = false;

    const res = await axios.delete(JAVA_API_SITE, { data: deleteObj });
    callBack(res.data);
  } catch {}
};

export const updateSite = (payload, callBack) => async dispatch => {
  try {
    let isAddMode = payload.id === '' ? true : false;

    let res = '';
    if (isAddMode) {
      res = await axios.post(JAVA_API_SITE, [payload]);
    } else {
      res = await axios.put(JAVA_API_SITE + '/' + payload.id, payload);
    }
    callBack(res.data);
  } catch {}
};
