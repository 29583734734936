import React, { Fragment, useEffect } from 'react';
import clsx from 'clsx';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { Hidden, Drawer, Paper } from '@material-ui/core';
import { connect, useSelector } from 'react-redux';
import SidebarHeader from '../SidebarHeader';
import SidebarMenu from '../SidebarMenu';
import { setSidebarToggleMobile } from '../../reducers/ThemeOptions';
import CustomNavItems from './CustomNavItems';

function SideBar(props) {
  const { setSidebarToggleMobile, sidebarToggleMobile, sidebarFixed, sidebarShadow, security, user } = props;
  const closeDrawer = () => setSidebarToggleMobile(!sidebarToggleMobile);
  const sidebarMenuContent = <div></div>;

  return (
    <Fragment>
      <Hidden lgUp>
        <Drawer anchor='left' open={sidebarToggleMobile} onClose={closeDrawer} variant='temporary' elevation={4} className='app-sidebar-wrapper-lg'>
          <SidebarHeader />
          <PerfectScrollbar>
            <CustomNavItems></CustomNavItems>
          </PerfectScrollbar>
        </Drawer>
      </Hidden>

      <Hidden mdDown>
        <Paper
          className={clsx('app-sidebar-wrapper', {
            'app-sidebar-wrapper-fixed': sidebarFixed,
          })}
          square
          elevation={sidebarShadow ? 11 : 3}
        >
          <SidebarHeader />
          <div
            className={clsx({
              'app-sidebar-menu': sidebarFixed,
            })}
          >
            <PerfectScrollbar options={{ wheelPropagation: false }}>
              <CustomNavItems></CustomNavItems>
            </PerfectScrollbar>
          </div>
        </Paper>
      </Hidden>
    </Fragment>
  );
}

function mapStateToProps(state) {
  return {
    user: state.security.user,
    sidebarFixed: state.ThemeOptions.sidebarFixed,
    headerFixed: state.ThemeOptions.headerFixed,
    sidebarToggleMobile: state.ThemeOptions.sidebarToggleMobile,
  };
}

const mapDispatchToProps = (dispatch) => ({
  setSidebarToggleMobile: (enable) => dispatch(setSidebarToggleMobile(enable)),
});

export default connect(mapStateToProps, mapDispatchToProps)(SideBar);
