import React, { useEffect } from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { useTranslation } from 'react-i18next';
import { Box } from '@material-ui/core';
import SubmitButton from './SubmitButton';
import CancelButton from './CancelButton';
import { ReactComponent as Cancel } from '../assets/icons/Cancel.svg';

export default function ConfirmModal(props) {
  const { t } = useTranslation();
  const [open, setOpen] = React.useState(props.open);

  useEffect(() => {
    setOpen(props.open);
  }, [props.open]);

  const handleClose = () => {
    props.onClose();
  };

  const handleSubmit = () => {
    props.onConfirm();
  };

  return (
    <div>
      <Dialog maxWidth='md' open={open} onClose={handleClose} aria-labelledby='form-dialog-title' className='confirm-modal'>
        <DialogTitle id='form-dialog-title'>
          <Box display='flex'>
            <Box flexGrow={1}>
              <span style={{ fontSize: '1rem' }}>{t('COMMON051')}</span>
            </Box>
            {<Cancel onClick={handleClose} style={{ height: 25, width: 25 }} />}
          </Box>
        </DialogTitle>
        <DialogContent>
          <Box display='flex' p={1}>
            {props.text}
          </Box>
        </DialogContent>
        <DialogActions>
          <SubmitButton onClick={handleSubmit} color='primary' variant='contained' size='small'>
            {t('yes')}
          </SubmitButton>
          <CancelButton onClick={handleClose} color='primary' variant='contained' size='small'>
            {t('no')}
          </CancelButton>
        </DialogActions>
      </Dialog>
    </div>
  );
}
