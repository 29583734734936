export const JAVA_API_LOGIN = '/api/v2.0/authenticate';

export const JAVA_API_DEPARTMENT = '/api/v2.0/department';
export const JAVA_API_DEPARTMENT_SEARCH = '/api/v2.0/department/search';

export const JAVA_API_DESIGNATION = '/api/v2.0/designation';
export const JAVA_API_DESIGNATION_SEARCH = '/api/v2.0/designation/search';

export const JAVA_API_REGISTER_USER = '/api/v2.0/user';
export const JAVA_API_MEMBERSHIP = '/api/v2.0/membership';

export const JAVA_API_FORGOT_PASSWORD = '/api/v2.0/users/password';

export const JAVA_API_COMPANY = '/api/v2.0/company';

export const JAVA_API_SITE_TYPE = '/api/v2.0/siteType';
export const JAVA_API_SITE_TYPE_SEARCH = '/api/v2.0/siteType/search';

export const JAVA_API_ADDRESS_TYPE = '/api/v2.0/addressType';
export const JAVA_API_ADDRESS_TYPE_SEARCH = '/api/v2.0/addressType/search';

export const JAVA_API_ZONE = '/api/v2.0/zone';
export const JAVA_API_ZONE_SEARCH = '/api/v2.0/zone/search';

export const JAVA_API_SITE = '/api/v2.0/site';
export const JAVA_API_SITE_SEARCH = '/api/v2.0/site/search';

export const JAVA_API_PERSON = '/api/v2.0/employee';
export const JAVA_API_PERSON_SEARCH = '/api/v2.0/emp';

export const JAVA_API_ADDRESS = '/api/v2.0/address';
