import React, { useEffect } from 'react';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Box, FormControl, Grid, InputLabel, MenuItem, Select, Typography, Card, CardHeader, CardContent, Paper, FormGroup } from '@material-ui/core';
import CommonUtil from '../../components/Util/CommonUtils';
import { getSites, updateSite } from '../../actions/SiteApiCalls';
import CancelButton from '../../components/CancelButton';
import SubmitButton from '../../components/SubmitButton';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import { useHistory } from 'react-router-dom';
import Switch from '@material-ui/core/Switch';
import { REACT_URI_SITE } from '../../actions/EndPoints';
import { toast } from 'react-toastify';
import { getSiteTypes } from '../../actions/SiteTypeApiCalls';
import { getAddressTypes } from '../../actions/AddressTypeApiCalls';
import { cityData, countryData, stateData, timeZoneData } from './AddressFormData';
import { ReactComponent as Cancel } from '../../assets/icons/Cancel.svg';

const useStyles = makeStyles((theme) => ({
  textarea: {
    resize: 'both',
  },
}));

const IOSSwitch = withStyles((theme) => ({
  root: {
    width: 42,
    height: 26,
    padding: 0,
    margin: theme.spacing(1),
  },
  switchBase: {
    padding: 1,
    '&$checked': {
      transform: 'translateX(16px)',
      color: theme.palette.common.white,
      '& + $track': {
        backgroundColor: '#1976D2',
        opacity: 1,
        border: 'none',
      },
    },
    '&$focusVisible $thumb': {
      color: '#52d869',
      border: '6px solid #fff',
    },
  },
  thumb: {
    width: 24,
    height: 24,
  },
  track: {
    borderRadius: 26 / 2,
    border: `1px solid ${theme.palette.grey[400]}`,
    backgroundColor: theme.palette.grey[50],
    opacity: 1,
    transition: theme.transitions.create(['background-color', 'border']),
  },
  checked: {},
  focusVisible: {},
}))(({ classes, ...props }) => {
  return (
    <Switch
      focusVisibleClassName={classes.focusVisible}
      disableRipple
      classes={{
        root: classes.root,
        switchBase: classes.switchBase,
        thumb: classes.thumb,
        track: classes.track,
        checked: classes.checked,
      }}
      {...props}
    />
  );
});

export default function AddSite(props) {
  const classes = useStyles();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  let history = useHistory();
  const store = useSelector((state) => state);
  const [open, setOpen] = React.useState(props.open);
  // eslint-disable-next-line
  const [saveType, setSaveType] = React.useState(false);
  // eslint-disable-next-line
  const [data, setData] = React.useState();
  const [siteTypes, setSiteTypes] = React.useState([]);
  const [parentSites, setParentSites] = React.useState([]);
  const [addressTypes, setAddressTypes] = React.useState([]);
  const [countries, setCountries] = React.useState([]);
  const [selectStates, setSelectStates] = React.useState([]);
  const [cities, setCities] = React.useState([]);
  const [timeZones, setTimeZones] = React.useState([]);

  const [state, setState] = React.useState({
    id: '',
    name: '',
    description: '',
    parentId: '',
    siteTypeId: '',
    address: {
      id: '',
      addressLine1: '',
      addressLine2: '',
      addressLine3: '',
      country: '',
      state: '',
      city: '',
      areaCode: 0,
      lattitude: 0,
      longitude: 0,
      radius: 0,
      timeZone: '',
      dayLightSaving: true,
      isDefault: true,
      addressTypeId: '',
      employeeId: '',
      userId: '',
      siteId: '',
    },
  });

  const [error, setError] = React.useState({
    name: false,
    description: false,
    parentId: false,
    siteTypeId: false,
    address: {
      addressLine1: false,
      addressLine2: false,
      addressLine3: false,
      country: false,
      state: false,
      city: false,
      areaCode: false,
      lattitude: false,
      longitude: false,
      radius: false,
      timeZone: false,
      dayLightSaving: false,
      isDefault: false,
      addressTypeId: false,
      employeeId: false,
      userId: false,
      siteId: false,
    },
  });

  useEffect(() => {
    console.log('data===' + JSON.stringify(props.selectedObject));
    setOpen(props.open);
    if (!CommonUtil.isEmpty(props.selectedObject) && !CommonUtil.isEmpty(props.selectedObject.address)) {
      setState({
        id: props.selectedObject.id,
        name: props.selectedObject.name,
        description: props.selectedObject.description,
        parentId: props.selectedObject.parentId,
        siteTypeId: props.selectedObject.siteTypeId,
        address: {
          id: props.selectedObject.address.id,
          addressLine1: props.selectedObject.address.addressLine1,
          addressLine2: props.selectedObject.address.addressLine2,
          addressLine3: props.selectedObject.address.addressLine3,
          country: props.selectedObject.address.country,
          state: props.selectedObject.address.state,
          city: props.selectedObject.address.city,
          areaCode: props.selectedObject.address.areaCode,
          lattitude: props.selectedObject.address.lattitude,
          longitude: props.selectedObject.address.longitude,
          radius: props.selectedObject.address.radius,
          timeZone: props.selectedObject.address.timeZone,
          dayLightSaving: props.selectedObject.address.dayLightSaving,
          isDefault: props.selectedObject.address.isDefault,
          addressTypeId: props.selectedObject.address.addressTypeId,
          employeeId: props.selectedObject.address.employeeId,
          userId: props.selectedObject.address.userId,
          siteId: props.selectedObject.address.siteId,
        },
      });
    }
  }, [props.open, props.selectedObject]);
  useEffect(() => {
    dispatch(
      getSites(0, 0, false, (data) => {
        setParentSites(data.site);
      })
    );

    dispatch(
      getSiteTypes(0, 0, false, (data) => {
        setSiteTypes(data.siteType);
      })
    );

    dispatch(
      getAddressTypes(0, 0, false, (data) => {
        setAddressTypes(data.addressType);
      })
    );

    setCountries(countryData);
    setSelectStates(stateData);
    setCities(cityData);
    setTimeZones(timeZoneData);
  }, []);

  useEffect(() => {
    if (store.errors && !CommonUtil.isEmpty(store.errors.siteError)) {
      const { siteError } = store.errors;
      switch (siteError.code) {
        case 'LMSE0025':
          setError({ name: true });
          break;
        case 'LMSE0026':
          setError({ siteTypeId: true });
          break;
        default:
      }
    }
    // eslint-disable-next-line
  }, [store.errors.siteError]);

  const handleClose = () => {
    props.onClose(false);
  };

  const handleChange = (event) => {
    const name = event.target.name;
    setState({
      ...state,
      [name]: event.target.value,
    });

    setError({
      [name]: false,
    });
  };

  const handleAddressChange = (event) => {
    const name = event.target.name;
    setState({
      ...state,
      address: {
        ...state.address,
        [name]: event.target.value,
      },
    });

    setError({
      [name]: false,
    });
  };

  const handleAddressChange1 = (event) => {
    const name = event.target.name;
    setState({
      ...state,
      address: {
        ...state.address,
        [name]: event.target.checked,
      },
    });

    setError({
      [name]: false,
    });
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    if (isFormValid(state)) {
      if (state.address.addressLine1 === '' && state.address.city === '' && state.address.addressTypeId === '') {
        let siteObj = {};
        siteObj.id = state.id;
        siteObj.name = state.name;
        siteObj.description = state.description;
        siteObj.siteTypeId = state.siteTypeId;
        siteObj.parentId = state.parentId;
        dispatch(
          updateSite(siteObj, (data) => {
            handleError(data);
          })
        );
      } else {
        dispatch(
          updateSite(state, (data) => {
            handleError(data);
          })
        );
      }
    }
  };

  const isFormValid = (event) => {
    if (CommonUtil.isEmptyString(state.name)) {
      setError({ name: true });
      toast.error(t('SITE010'));
      return false;
    }
    if (!CommonUtil.isValidNumericName(state.name)) {
      setError({ name: true });
      toast.error(t('SITE012'));
      return false;
    }
    if (CommonUtil.isEmptyString(state.siteTypeId)) {
      setError({ siteTypeId: true });
      toast.error(t('SITE011'));
      return false;
    }
    return true;
  };

  const handleError = (data) => {
    switch (data.code) {
      case 'LMSI0013':
        history.push(REACT_URI_SITE);
        break;
      case 'LMSW0001':
        data = data.data.error[0];
        break;
      case 'LMSI0014':
        history.push(REACT_URI_SITE);
        break;
      default:
        break;
    }

    switch (data.code) {
      case 'LMSE0023':
        setError({ name: true });
        break;
      case 'LMSE0025':
        setError({ name: true });
        break;
      case 'LMSE0026':
        setError({ siteTypeId: true });
      case 'LMSE0008':
        setError({ siteTypeId: true });
        break;
      case 'LMSE0034':
        setError({ parentId: true });
        break;
      case 'LMSE0028':
        setError({ parentId: true });
        break;
      case 'LMSE0041':
        setError({ parentId: true });
        break;
      case 'LMSE0021':
        setError({ addressTypeId: true });
        break;
      case 'LMSE0036':
        setError({ addressLine1: true });
        break;
      case 'LMSE0021':
        setError({ addressTypeId: true });
        break;
      case 'LMSE0027':
        setError({ addressTypeId: true });
        break;
      case 'LMSE0038':
        setError({ isDefault: true });
        break;
      case 'LMSE0037':
        setError({ city: true });
        break;
      default:
        break;
    }

    if (data.code === 'LMSI0013' || data.code === 'LMSI0014') {
      toast.success(data.message);
      handleClose();
    } else {
      toast.error(data.message);
    }
  };

  return (
    <>
      <Dialog maxWidth='sm' open={open} onClose={handleClose} aria-labelledby='form-dialog-title' className='custom-modal'>
        <DialogTitle id='form-dialog-title'>
          <Box display='flex'>
            <Box flexGrow={1}>{CommonUtil.isEmptyString(state.id) ? t('SITE002') : t('SITE008')}</Box>
            <Cancel onClick={handleClose} style={{ height: 25, width: 25 }} />
          </Box>
        </DialogTitle>
        <DialogContent>
          {/* <form noValidate autoComplete="off"> */}
          <>
            <Grid container justifyContent='center' alignItems='center'>
              <Grid item xs={12} sm={6}>
                <Box display='flex' justifyContent='center' m={1} p={1}>
                  <TextField
                    fullWidth
                    required
                    error={error.name}
                    name='name'
                    label={t('COMMON044')}
                    helperText=''
                    variant='outlined'
                    size='small'
                    defaultValue={state.name}
                    onChange={handleChange}
                  />
                </Box>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Box display='flex' justifyContent='center' m={1} p={1}>
                  <FormControl variant='outlined' size='small' fullWidth>
                    <InputLabel>{t('STYPE001')}</InputLabel>
                    <Select
                      required
                      label={t('STYPE001')}
                      error={error.siteTypeId}
                      name='siteTypeId'
                      onChange={handleChange}
                      value={state.siteTypeId}
                      defaultValue={state.siteTypeId}
                    >
                      <MenuItem value='' selected>
                        <em>None</em>
                      </MenuItem>
                      {siteTypes ? (
                        siteTypes.map((data, index) => (
                          <MenuItem key={index} value={data.id} style={{ maxWidth: '230px' }}>
                            <Typography variant='inherit' noWrap>
                              {data.name}
                            </Typography>
                          </MenuItem>
                        ))
                      ) : (
                        <></>
                      )}
                    </Select>
                  </FormControl>
                </Box>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Box display='flex' justifyContent='center' m={1} p={1}>
                  <FormControl variant='outlined' size='small' fullWidth>
                    <InputLabel>{t('COMMON050')}</InputLabel>
                    <Select label={t('COMMON050')} error={error.parentId} name='parentId' onChange={handleChange} value={state.parentId} defaultValue={state.parentId}>
                      <MenuItem value='' selected>
                        <em>None</em>
                      </MenuItem>
                      {parentSites ? (
                        parentSites.map((data, index) => (
                          <MenuItem key={index} value={data.id} style={{ maxWidth: '230px' }}>
                            <Typography variant='inherit' noWrap>
                              {data.name}
                            </Typography>
                          </MenuItem>
                        ))
                      ) : (
                        <></>
                      )}
                    </Select>
                  </FormControl>
                </Box>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Box display='flex' justifyContent='center' m={1} p={1}>
                  <TextField
                    inputProps={{ className: classes.textarea }}
                    fullWidth
                    size='small'
                    variant='outlined'
                    aria-label='maximum height'
                    placeholder={t('DEPT009')}
                    value={state.description}
                    name='description'
                    onChange={handleChange}
                    multiline
                  />
                </Box>
              </Grid>

              <Card>
                <CardHeader
                  style={{ backgroundColor: '#1976d2', height: '5px' }}
                  subheader={
                    <Typography style={{ color: '#FFFFFF' }}>
                      <b>{t('ADD012')}</b>
                    </Typography>
                  }
                />
                <CardContent>
                  <form noValidate autoComplete='off'>
                    <Grid container justifyContent='center' alignItems='center'>
                      <Grid item xs={12} sm={6}>
                        <Box display='flex' justifyContent='center' m={1} p={1}>
                          <TextField
                            fullWidth
                            error={error.addressLine1}
                            name='addressLine1'
                            label={t('ADD001')}
                            helperText=''
                            variant='outlined'
                            size='small'
                            defaultValue={state.address.addressLine1}
                            onChange={handleAddressChange}
                          />
                        </Box>
                      </Grid>

                      <Grid item xs={12} sm={6}>
                        <Box display='flex' justifyContent='center' m={1} p={1}>
                          <TextField
                            fullWidth
                            error={error.addressLine2}
                            name='addressLine2'
                            label={t('ADD002')}
                            helperText=''
                            variant='outlined'
                            size='small'
                            defaultValue={state.address.addressLine2}
                            onChange={handleAddressChange}
                          />
                        </Box>
                      </Grid>

                      <Grid item xs={12} sm={6}>
                        <Box display='flex' justifyContent='center' m={1} p={1}>
                          <TextField
                            fullWidth
                            error={error.addressLine3}
                            name='addressLine3'
                            label={t('Address Line3')}
                            helperText=''
                            variant='outlined'
                            size='small'
                            defaultValue={state.address.addressLine3}
                            onChange={handleAddressChange}
                          />
                        </Box>
                      </Grid>

                      <Grid item xs={12} sm={6}>
                        <Box display='flex' justifyContent='center' m={1} p={1}>
                          <FormControl variant='outlined' size='small' fullWidth>
                            <InputLabel>{t('ADD003')}</InputLabel>
                            <Select
                              label={t('ADD003')}
                              error={error.country}
                              name='country'
                              onChange={handleAddressChange}
                              value={state.address.country}
                              defaultValue={state.address.country}
                            >
                              <MenuItem value='' selected>
                                <em>None</em>
                              </MenuItem>
                              {countries ? (
                                countries.map((data, index) => (
                                  <MenuItem key={index} value={data.id} style={{ maxWidth: '230px' }}>
                                    <Typography variant='inherit' noWrap>
                                      {data.name}
                                    </Typography>
                                  </MenuItem>
                                ))
                              ) : (
                                <></>
                              )}
                            </Select>
                          </FormControl>
                        </Box>
                      </Grid>

                      <Grid item xs={12} sm={6}>
                        <Box display='flex' justifyContent='center' m={1} p={1}>
                          <FormControl variant='outlined' size='small' fullWidth>
                            <InputLabel>{t('ADD004')}</InputLabel>
                            <Select
                              label={t('ADD004')}
                              error={error.state}
                              name='state'
                              onChange={handleAddressChange}
                              value={state.address.state}
                              defaultValue={state.address.state}
                            >
                              <MenuItem value='' selected>
                                <em>None</em>
                              </MenuItem>
                              {selectStates ? (
                                selectStates.map((data, index) => (
                                  <MenuItem key={index} value={data.id} style={{ maxWidth: '230px' }}>
                                    <Typography variant='inherit' noWrap>
                                      {data.name}
                                    </Typography>
                                  </MenuItem>
                                ))
                              ) : (
                                <></>
                              )}
                            </Select>
                          </FormControl>
                        </Box>
                      </Grid>

                      <Grid item xs={12} sm={6}>
                        <Box display='flex' justifyContent='center' m={1} p={1}>
                          <FormControl variant='outlined' size='small' fullWidth>
                            <InputLabel>{t('ADD005')}</InputLabel>
                            <Select label={t('ADD005')} error={error.city} name='city' onChange={handleAddressChange} value={state.address.city} defaultValue={state.address.city}>
                              <MenuItem value='' selected>
                                <em>None</em>
                              </MenuItem>
                              {cities ? (
                                cities.map((data, index) => (
                                  <MenuItem key={index} value={data.id} style={{ maxWidth: '230px' }}>
                                    <Typography variant='inherit' noWrap>
                                      {data.name}
                                    </Typography>
                                  </MenuItem>
                                ))
                              ) : (
                                <></>
                              )}
                            </Select>
                          </FormControl>
                        </Box>
                      </Grid>

                      <Grid item xs={12} sm={6}>
                        <Box display='flex' justifyContent='center' m={1} p={1}>
                          <TextField
                            fullWidth
                            error={error.areaCode}
                            name='areaCode'
                            label={t('ADD006')}
                            helperText=''
                            variant='outlined'
                            size='small'
                            defaultValue={state.address.areaCode}
                            onChange={handleAddressChange}
                          />
                        </Box>
                      </Grid>

                      <Grid item xs={12} sm={6}>
                        <Box display='flex' justifyContent='center' m={1} p={1}>
                          <TextField
                            fullWidth
                            error={error.lattitude}
                            name='lattitude'
                            label={t('ADD007')}
                            helperText=''
                            variant='outlined'
                            size='small'
                            defaultValue={state.address.lattitude}
                            onChange={handleAddressChange}
                          />
                        </Box>
                      </Grid>

                      <Grid item xs={12} sm={6}>
                        <Box display='flex' justifyContent='center' m={1} p={1}>
                          <TextField
                            fullWidth
                            error={error.longitude}
                            name='longitude'
                            label={t('ADD008')}
                            helperText=''
                            variant='outlined'
                            size='small'
                            defaultValue={state.address.longitude}
                            onChange={handleAddressChange}
                          />
                        </Box>
                      </Grid>

                      <Grid item xs={12} sm={6}>
                        <Box display='flex' justifyContent='center' m={1} p={1}>
                          <TextField
                            fullWidth
                            error={error.radius}
                            name='radius'
                            label={t('ADD009')}
                            helperText=''
                            variant='outlined'
                            size='small'
                            defaultValue={state.address.radius}
                            onChange={handleAddressChange}
                          />
                        </Box>
                      </Grid>

                      <Grid item xs={12} sm={6}>
                        <Box display='flex' justifyContent='center' m={1} p={1}>
                          <FormControl variant='outlined' size='small' fullWidth>
                            <InputLabel>{t('ADD010')}</InputLabel>
                            <Select
                              label={t('ADD010')}
                              error={error.timeZone}
                              name='timeZone'
                              onChange={handleAddressChange}
                              value={state.address.timeZone}
                              defaultValue={state.address.timeZone}
                            >
                              <MenuItem value='' selected>
                                <em>None</em>
                              </MenuItem>
                              {timeZones ? (
                                timeZones.map((data, index) => (
                                  <MenuItem key={index} value={data.id} style={{ maxWidth: '230px' }}>
                                    <Typography variant='inherit' noWrap>
                                      {data.name}
                                    </Typography>
                                  </MenuItem>
                                ))
                              ) : (
                                <></>
                              )}
                            </Select>
                          </FormControl>
                        </Box>
                      </Grid>

                      <Grid item xs={12} sm={6}>
                        <Box display='flex' justifyContent='center' m={1} p={1}>
                          <FormControl variant='outlined' size='small' fullWidth>
                            <InputLabel>{t('ATYPE001')}</InputLabel>
                            <Select
                              label={t('ATYPE001')}
                              error={error.addressTypeId}
                              name='addressTypeId'
                              onChange={handleAddressChange}
                              value={state.address.addressTypeId}
                              defaultValue={state.address.addressTypeId}
                            >
                              <MenuItem value='' selected>
                                <em>None</em>
                              </MenuItem>
                              {addressTypes ? (
                                addressTypes.map((data, index) => (
                                  <MenuItem key={data.id} value={data.id} style={{ maxWidth: '230px' }}>
                                    <Typography variant='inherit' noWrap>
                                      {data.name}
                                    </Typography>
                                  </MenuItem>
                                ))
                              ) : (
                                <></>
                              )}
                            </Select>
                          </FormControl>
                        </Box>
                      </Grid>

                      <Grid item xs={12}>
                        <Box display='flex' m={1} p={1}>
                          <FormGroup>
                            <Typography component='div'>
                              <Grid component='label' container alignItems='center' spacing={1}>
                                <Grid item>{t('ADD011')}</Grid>
                                <Grid item>Off</Grid>
                                <Grid item>
                                  <IOSSwitch checked={state.address.dayLightSaving} onChange={handleAddressChange1} name='dayLightSaving' />
                                </Grid>
                                <Grid item>On</Grid>
                              </Grid>
                            </Typography>
                          </FormGroup>
                        </Box>
                      </Grid>
                    </Grid>
                  </form>
                </CardContent>
              </Card>
            </Grid>
            {/* </form> */}
          </>
        </DialogContent>
        <DialogActions>
          <SubmitButton onClick={handleSubmit} color='primary' variant='contained' size='small'>
            {t('COMMON035')}
          </SubmitButton>
          <CancelButton onClick={handleClose} color='primary' variant='contained' size='small'>
            {t('COMMON008')}
          </CancelButton>
        </DialogActions>
      </Dialog>
    </>
  );
}
