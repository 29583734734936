import { Dialog, DialogContent, DialogTitle, Grid, makeStyles } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import React, { useEffect } from 'react';
import { Box, InputLabel, MenuItem, Select, Typography, FormControl } from '@material-ui/core';
import { TextField } from '@material-ui/core';
import { DialogActions } from '@material-ui/core';
import SubmitButton from '../../components/SubmitButton';
import CommonUtil from '../../components/Util/CommonUtils';
import CancelButton from '../../components/CancelButton';
import { toast } from 'react-toastify';
import { getDepartments } from '../../actions/DepartmentApiCalls';
import { getDesignations } from '../../actions/DesignationApiCalls';
import { addPerson } from '../../actions/PersonApiCalls';
import { useHistory } from 'react-router';
import { REACT_URI_PERSON } from '../../actions/EndPoints';
import Avatar from '@material-ui/core/Avatar';
import AddAPhotoSharpIcon from '@material-ui/icons/AddAPhotoSharp';
import { ReactComponent as Cancel } from '../../assets/icons/Cancel.svg';

export default function AddPerson(props) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [departments, setDepartments] = React.useState([]);
  const [designations, setDesignations] = React.useState([]);
  const [open, setOpen] = React.useState(props.open);
  const [error, setError] = React.useState({});
  let history = useHistory();

  const [state, setState] = React.useState({
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
    employeeCode: '',
    joinDate: '',
    gender: '',
    departmentId: '',
    designationId: '',
    profilePhoto: '',
  });

  const handleClose = () => {
    props.onClose(false);
  };

  const handleChange = (event) => {
    const name = event.target.name;
    setState({
      ...state,
      [name]: event.target.value,
    });

    setError({
      [name]: false,
    });
  };

  const uploadImage = async (e) => {
    const file = e.target.files[0];
    const base64Data = await convertBase64(file);
    setState({
      ...state,
      profilePhoto: base64Data,
    });
  };

  const convertBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);

      fileReader.onload = () => {
        resolve(fileReader.result);
      };

      fileReader.onerror = (error) => {
        reject(error);
      };
    });
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    if (isFormValid(state)) {
      let personObj = {};
      personObj.firstName = state.firstName;
      personObj.lastName = state.lastName;
      personObj.email = state.email;
      personObj.phone = state.phone;
      personObj.employeeCode = state.employeeCode;
      personObj.joinDate = state.joinDate;
      personObj.gender = state.gender;
      personObj.departmentId = state.departmentId;
      personObj.designationId = state.designationId;
      personObj.profilePhoto = state.profilePhoto;
      dispatch(
        addPerson(personObj, (data) => {
          handleError(data);
        })
      );
    }
  };

  const isFormValid = (event) => {
    if (CommonUtil.isEmptyString(state.firstName)) {
      setError({ firstName: true });
      toast.error(t('PERSON016'));
      return false;
    }
    if (!CommonUtil.isValidNumericName(state.firstName)) {
      setError({ firstName: true });
      toast.error(t('PERSON017'));
      return false;
    }

    if (!CommonUtil.isValidNumericName(state.lastName)) {
      setError({ lastName: true });
      toast.error(t('PERSON018'));
      return false;
    }

    // if (CommonUtil.isEmptyString(state.email) && CommonUtil.isEmptyString(state.phone)) {
    //   setError({ email: true });
    //   toast.error(t('PERSON021'));
    //   return false;
    // }

    // if (!CommonUtil.isValidEmail(state.email)) {
    //   setError({ email: true });
    //   toast.error(t('PERSON022'));
    //   return false;
    // }

    if (!CommonUtil.isValidMobile(state.phone)) {
      setError({ phone: true });
      toast.error(t('PERSON023'));
      return false;
    }

    if (CommonUtil.isEmptyString(state.employeeCode)) {
      setError({ employeeCode: true });
      toast.error(t('PERSON019'));
      return false;
    }

    if (!CommonUtil.isValidCode(state.employeeCode)) {
      setError({ employeeCode: true });
      toast.error(t('PERSON020'));
      return false;
    }

    if (CommonUtil.isEmptyString(state.gender)) {
      setError({ gender: true });
      toast.error(t('PERSON025'));
      return false;
    }

    return true;
  };

  const handleError = (data) => {
    switch (data.code) {
      case 'OMSI0006':
        history.push(REACT_URI_PERSON);
        break;
      case 'OMSE0097':
        setError({ employeeCode: true });
        break;
      case 'OMSE0184':
        setError({ email: true });
        break;
      case 'OMSE0213':
        setError({ phone: true });
        break;
      case 'OMSE0214':
        setError({ phone: true });
        break;
      case 'OMSE0160':
        setError({ email: true });
        break;
      default:
        break;
    }

    if (data.code === 'OMSI0006' || data.code === 'OMSI0007') {
      toast.success(data.message);
      handleClose();
    } else {
      toast.error(data.message);
    }
  };

  useEffect(() => {
    dispatch(
      getDepartments(0, 0, false, (data) => {
        setDepartments(data.department);
      })
    );
  }, []);

  useEffect(() => {
    dispatch(
      getDesignations(0, 0, false, (data) => {
        setDesignations(data.designation);
      })
    );
  }, []);

  return (
    <div>
      <Dialog maxWidth='md' open={open} onClose={handleClose} aria-labelledby='form-dialog-title' className='custom-modal'>
        <DialogTitle id='form-dialog-title'>
          <Box display='flex'>
            <Box flexGrow={1}>{t('PERSON002')}</Box>
            <Cancel onClick={handleClose} style={{ height: 25, width: 25 }} />
          </Box>
        </DialogTitle>
        <DialogContent>
          <form noValidate autoComplete='off'>
            <Grid container justifyContent='center' alignItems='center'>
              <Grid container>
                <Grid container justifyContent='center'>
                  <Grid item md={3} sm={6} xs={12}>
                    <Box display='flex' justifyContent='center' m={1} p={1}>
                      <SubmitButton component='label' size='small'>
                        <Avatar>
                          <AddAPhotoSharpIcon />
                        </Avatar>
                        <input id='profilePhoto' name='profilePhoto' type='file' onChange={uploadImage} accept='image/*' hidden />
                        {state.profilePhoto != '' && <img src={state.profilePhoto} style={{ height: 50, width: 50, borderRadius: '50%' }}></img>}
                      </SubmitButton>
                    </Box>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Box display='flex' justifyContent='center' m={1} p={1}>
                  <TextField
                    fullWidth
                    required
                    error={error.firstName}
                    name='firstName'
                    label={t('PERSON003')}
                    variant='outlined'
                    size='small'
                    defaultValue={state.firstName}
                    onChange={handleChange}
                  />
                </Box>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Box display='flex' justifyContent='center' m={1} p={1}>
                  <TextField
                    fullWidth
                    error={error.lastName}
                    name='lastName'
                    label={t('PERSON004')}
                    variant='outlined'
                    size='small'
                    defaultValue={state.lastName}
                    onChange={handleChange}
                  />
                </Box>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Box display='flex' justifyContent='center' m={1} p={1}>
                  <TextField fullWidth error={error.email} name='email' label={t('PERSON005')} variant='outlined' size='small' defaultValue={state.email} onChange={handleChange} />
                </Box>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Box display='flex' justifyContent='center' m={1} p={1}>
                  <TextField fullWidth error={error.phone} name='phone' label={t('PERSON006')} variant='outlined' size='small' defaultValue={state.phone} onChange={handleChange} />
                </Box>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Box display='flex' justifyContent='center' m={1} p={1}>
                  <TextField
                    fullWidth
                    required
                    error={error.employeeCode}
                    name='employeeCode'
                    label={t('PERSON008')}
                    variant='outlined'
                    size='small'
                    defaultValue={state.employeeCode}
                    onChange={handleChange}
                  />
                </Box>
              </Grid>

              <Grid item xs={12} sm={6}>
                <Box display='flex' justifyContent='center' m={1} p={1}>
                  <FormControl variant='outlined' size='small' fullWidth>
                    <InputLabel>{t('PERSON007')}</InputLabel>
                    <Select
                      fullWidth
                      error={error.gender}
                      name='gender'
                      label={t('PERSON007')}
                      variant='outlined'
                      size='small'
                      value={state.gender}
                      defaultValue={state.gender}
                      onChange={handleChange}
                    >
                      <MenuItem value={'M'}>Male</MenuItem>
                      <MenuItem value={'F'}>Female</MenuItem>
                      <MenuItem value={'O'}>Others</MenuItem>
                    </Select>
                  </FormControl>
                </Box>
              </Grid>

              <Grid item xs={12} sm={6}>
                <Box display='flex' justifyContent='center' m={1} p={1}>
                  <FormControl variant='outlined' size='small' fullWidth>
                    <InputLabel>{t('PERSON010')}</InputLabel>
                    <Select
                      fullWidth
                      error={error.departmentId}
                      name='departmentId'
                      label={t('PERSON010')}
                      variant='outlined'
                      size='small'
                      value={state.departmentId}
                      defaultValue={state.departmentId}
                      onChange={handleChange}
                    >
                      <MenuItem value='' selected>
                        <em>None</em>
                      </MenuItem>
                      {departments ? (
                        departments.map((data, index) => (
                          <MenuItem key={index} value={data.id} style={{ maxWidth: '230px' }}>
                            <Typography variant='inherit' noWrap>
                              {data.name}
                            </Typography>
                          </MenuItem>
                        ))
                      ) : (
                        <></>
                      )}
                    </Select>
                  </FormControl>
                </Box>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Box display='flex' justifyContent='center' m={1} p={1}>
                  <FormControl variant='outlined' size='small' fullWidth>
                    <InputLabel>{t('PERSON011')}</InputLabel>
                    <Select
                      fullWidth
                      error={error.designationId}
                      name='designationId'
                      label={t('PERSON011')}
                      variant='outlined'
                      size='small'
                      value={state.designationId}
                      defaultValue={state.designationId}
                      onChange={handleChange}
                    >
                      <MenuItem value='' selected>
                        <em>None</em>
                      </MenuItem>
                      {designations ? (
                        designations.map((data, index) => (
                          <MenuItem key={index} value={data.id} style={{ maxWidth: '230px' }}>
                            <Typography variant='inherit' noWrap>
                              {data.name}
                            </Typography>
                          </MenuItem>
                        ))
                      ) : (
                        <></>
                      )}
                    </Select>
                  </FormControl>
                </Box>
              </Grid>
            </Grid>
          </form>
        </DialogContent>
        <DialogActions>
          <SubmitButton onClick={handleSubmit} color='primary' variant='contained' size='small'>
            {t('COMMON035')}
          </SubmitButton>
          <CancelButton onClick={handleClose} color='primary' variant='contained' size='small'>
            {t('COMMON008')}
          </CancelButton>
        </DialogActions>
      </Dialog>
    </div>
  );
}
