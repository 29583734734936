import React, { Fragment, useEffect } from 'react';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Box, FormControl, Grid, InputLabel, MenuItem, Select, Typography } from '@material-ui/core';
import CommonUtil from '../../components/Util/CommonUtils';
import { getDepartments, updateDepartment } from '../../actions/DepartmentApiCalls';
import CancelButton from '../../components/CancelButton';
import SubmitButton from '../../components/SubmitButton';
import { makeStyles } from '@material-ui/core/styles';
import { REACT_URI_DEPARTMENT } from '../../actions/EndPoints';
import { useHistory } from 'react-router-dom';
import { ReactComponent as Cancel } from '../../assets/icons/Cancel.svg';
import { addMembership, getAllRoles, perosnWithUser, updateMembership } from '../../actions/PersonRoleActions';
import Autocomplete from '@material-ui/lab/Autocomplete';
import CircularProgress from '@material-ui/core/CircularProgress';
import { forEach } from 'lodash';
import { toast } from 'react-toastify';
import { SatelliteSharp } from '@material-ui/icons';

const useStyles = makeStyles((theme) => ({
  textarea: {
    resize: 'both',
  },
}));

export default function AddMembership(props) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [open, setOpen] = React.useState(props.open);
  // eslint-disable-next-line
  const [saveType, setSaveType] = React.useState(false);
  // eslint-disable-next-line
  const [data, setData] = React.useState();
  // eslint-disable-next-line

  const [openSearch, setOpenSearch] = React.useState(false);
  const [options, setOptions] = React.useState([]);
  const loading = openSearch && options.length === 0;

  const [roleList, setRoleList] = React.useState([]);
  const [personList, setPersonList] = React.useState([]);
  const [userId, setUserId] = React.useState([]);

  const [state, setState] = React.useState({
    applicationId: props.selectedObject.applicationId,
    userId: props.selectedObject.userId,
    membershipId: props.selectedObject.membershipId,
    roleId: props.selectedObject.roleId,
  });

  useEffect(() => {
    setOpen(props.open);
    setState({
      applicationId: props.selectedObject.applicationId,
      userId: props.selectedObject.userId,
      membershipId: props.selectedObject.membershipId,
      roleId: props.selectedObject.roleId,
    });
  }, [props.open, props.selectedObject]);

  useEffect(() => {
    dispatch(
      getAllRoles(state.applicationId, (data) => {
        setRoleList(data);
      })
    );
  }, []);

  const handleClose = () => {
    props.onClose(false);
  };

  const handleChange = (event) => {
    const name = event.target.name;
    setState({
      ...state,
      [name]: event.target.value,
    });
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    const obj = {};
    obj.userId = userId;
    obj.roleId = state.roleId;
    obj.applicationId = state.applicationId;
    if (state.userId != '' && state.userId != undefined) {
      obj.membershipId = state.membershipId;
      obj.userId = state.userId;
      dispatch(
        updateMembership(obj, (data) => {
          handleResponse(data);
        })
      );
    } else {
      dispatch(
        addMembership(obj, (data) => {
          handleResponse(data);
        })
      );
    }
  };

  const handleResponse = (data) => {
    toast.warning(data.message);
    handleClose();
  };

  const handleText = (event) => {
    const fieldName = event.target.name;
    setState({
      ...state,
      name: event.target.value,
    });
    if (fieldName === 'name') {
      if (state.name != undefined && state.name.length > 1) {
        dispatch(
          perosnWithUser(event.target.value, 0, 50, (data) => {
            setPersonList(data.data.employee);
          })
        );
      } else {
        setPersonList([]);
      }
    }
  };

  var id = '';
  function getSelectedItem(newValue) {
    newValue.map((member) => (id += member.userId + ','));
    setUserId(id);
  }

  return (
    <div>
      <Dialog maxWidth='xl' open={open} onClose={handleClose} aria-labelledby='form-dialog-title' className='custom-modal'>
        <DialogTitle id='form-dialog-title'>
          <Box display='flex'>
            <Box flexGrow={1}>{CommonUtil.isEmptyString(state.userId) ? t('MEMBER005') : t('MEMBER006')}</Box>
            <Cancel onClick={handleClose} style={{ height: 25, width: 25 }} />
          </Box>
        </DialogTitle>
        <DialogContent>
          <form noValidate autoComplete='off'>
            <Grid container justifyContent='center' alignItems='center'>
              <Grid item xs={12} sm={12}>
                <Box display='flex' justifyContent='center' m={1} p={1}>
                  <FormControl variant='outlined' size='small' fullWidth>
                    <InputLabel>{t('MEMBER004')}</InputLabel>
                    <Select label={t('MEMBER004')} name='roleId' onChange={handleChange} value={state.roleId} defaultValue={state.roleId}>
                      <MenuItem value='' selected>
                        <em>None</em>
                      </MenuItem>
                      {roleList ? (
                        roleList.map((data, index) => (
                          <MenuItem key={index} value={data.id} style={{ maxWidth: '230px' }}>
                            <Typography variant='inherit' noWrap>
                              {data.name}
                            </Typography>
                          </MenuItem>
                        ))
                      ) : (
                        <></>
                      )}
                    </Select>
                  </FormControl>
                </Box>
              </Grid>

              {!state.userId && (
                <Grid item xs={12} sm={12}>
                  <Autocomplete
                    multiple
                    size='small'
                    name='userId'
                    onChange={(event, newValue) => {
                      if (newValue != null) getSelectedItem(newValue);
                    }}
                    getOptionSelected={(option, value) => (option != undefined ? option.firstName === value.firstName : false)}
                    getOptionLabel={(option) => (option != undefined ? option.firstName : '')}
                    options={personList ? personList : []}
                    renderInput={(params) => <TextField size='small' name='name' onChange={handleText} {...params} label={t('MEMBER007')} variant='outlined' />}
                  />
                </Grid>
              )}
            </Grid>
          </form>
        </DialogContent>
        <DialogActions>
          <SubmitButton onClick={handleSubmit} color='primary' variant='contained' size='small'>
            {t('COMMON035')}
          </SubmitButton>
          <CancelButton onClick={handleClose} color='primary' variant='contained' size='small'>
            {t('COMMON008')}
          </CancelButton>
        </DialogActions>
      </Dialog>
    </div>
  );
}
