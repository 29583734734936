import React, { useEffect } from 'react';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Box, Grid } from '@material-ui/core';
import CommonUtil from '../../components/Util/CommonUtils';
import { updateSiteType } from '../../actions/SiteTypeApiCalls';
import CancelButton from '../../components/CancelButton';
import SubmitButton from '../../components/SubmitButton';
import { makeStyles } from '@material-ui/core/styles';
import { REACT_URI_SITE_TYPE } from '../../actions/EndPoints';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import { ReactComponent as Cancel } from '../../assets/icons/Cancel.svg';

const useStyles = makeStyles(theme => ({
  textarea: {
    resize: 'both',
  },
}));

export default function AddSiteType(props) {
  const classes = useStyles();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  let history = useHistory();
  const store = useSelector(state => state);
  const [open, setOpen] = React.useState(props.open);
  // eslint-disable-next-line
  const [saveType, setSaveType] = React.useState(false);
  // eslint-disable-next-line
  const [data, setData] = React.useState();
  const [state, setState] = React.useState({
    id: props.selectedObject.id,
    name: props.selectedObject.name,
    code: props.selectedObject.code,
    description: props.selectedObject.description,
  });

  const [error, setError] = React.useState({
    name: false,
    code: false,
    description: false,
  });

  useEffect(() => {
    setOpen(props.open);
    setState({
      id: props.selectedObject.id,
      name: props.selectedObject.name,
      code: props.selectedObject.code,
      description: props.selectedObject.description,
    });
  }, [props.open, props.selectedObject]);

  useEffect(() => {
    if (store.errors && !CommonUtil.isEmpty(store.errors.siteTypeError)) {
      const { siteTypeError } = store.errors;
      switch (siteTypeError.code) {
        case 'LMSE0006':
          setError({ name: true });
          break;
        case 'LMSE0007':
          setError({ code: true });
          break;
        default:
      }
    }
    // eslint-disable-next-line
  }, [store.errors.siteTypeError]);

  const handleClose = () => {
    props.onClose(false);
  };

  const handleChange = event => {
    const name = event.target.name;
    setState({
      ...state,
      [name]: event.target.value,
    });

    setError({
      [name]: false,
    });
  };

  const handleSubmit = event => {
    event.preventDefault();
    if (isFormValid(state)) {
      let siteTypeObj = {};
      siteTypeObj.id = state.id;
      siteTypeObj.name = state.name;
      siteTypeObj.code = state.code;
      siteTypeObj.description = state.description;
      dispatch(
        updateSiteType(siteTypeObj, data => {
          handleError(data);
        }),
      );
    }
  };
  const isFormValid = event => {
    if (
      CommonUtil.isEmptyString(state.name) &&
      CommonUtil.isEmptyString(state.code)
    ) {
      setError({ name: true });
      toast.error(t('STYPE010'));
      return false;
    }
    if (CommonUtil.isEmptyString(state.code)) {
      setError({ code: true });
      toast.error(t('STYPE011'));
      return false;
    }
    if (CommonUtil.isEmptyString(state.name)) {
      setError({ name: true });
      toast.error(t('STYPE010'));
      return false;
    }
    if (!CommonUtil.isValidNumericName(state.name)) {
      setError({ name: true });
      toast.error(t('STYPE012'));
      return false;
    }
    if (!CommonUtil.isValidCode(state.code)) {
      setError({ code: true });
      toast.error(t('STYPE013'));
      return false;
    }
    return true;
  };

  const handleError = data => {
    switch (data.code) {
      case 'LMSI0005':
        history.push(REACT_URI_SITE_TYPE);
        break;
      case 'LMSW0001':
        data = data.data.error[0];
        break;
      case 'LMSI0006':
        history.push(REACT_URI_SITE_TYPE);
        break;
      default:
        break;
    }

    switch (data.code) {
      case 'LMSE0006':
        setError({ name: true });
        break;
      case 'LMSE0007':
        setError({ code: true });
        break;
      case 'LMSE0009':
        setError({ code: true });
        break;
      case 'LMSE0010':
        setError({ name: true });
        break;
      default:
        break;
    }

    if (data.code === 'LMSI0005' || data.code === 'LMSI0006') {
      toast.success(data.message);
      handleClose();
    } else {
      toast.error(data.message);
    }
  };

  return (
    <div>
      <Dialog
        maxWidth="sm"
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
        className="custom-modal"
      >
        <DialogTitle id="form-dialog-title">
          <Box display="flex">
            <Box flexGrow={1}>
              {CommonUtil.isEmptyString(state.id)
                ? t('STYPE002')
                : t('STYPE008')}
            </Box>
            <Cancel onClick={handleClose} style={{ height: 25, width: 25 }} />
          </Box>
        </DialogTitle>
        <DialogContent>
          <form noValidate autoComplete="off">
            <Grid container justify="center" alignItems="center">
              <Grid item xs={12} sm={6}>
                <Box display="flex" justifyContent="center" m={1} p={1}>
                  <TextField
                    fullWidth
                    required
                    error={error.name}
                    name="name"
                    label={t('COMMON044')}
                    helperText=""
                    variant="outlined"
                    size="small"
                    defaultValue={state.name}
                    onChange={handleChange}
                  />
                </Box>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Box display="flex" justifyContent="center" m={1} p={1}>
                  <TextField
                    fullWidth
                    required
                    error={error.code}
                    name="code"
                    label={t('COMMON045')}
                    helperText=""
                    variant="outlined"
                    size="small"
                    defaultValue={state.code}
                    onChange={handleChange}
                  />
                </Box>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Box display="flex" justifyContent="center" m={1} p={1}>
                  <TextField
                    fullWidth
                    size="small"
                    inputProps={{ className: classes.textarea }}
                    variant="outlined"
                    aria-label="maximum height"
                    placeholder={t('STYPE009')}
                    value={state.description}
                    name="description"
                    onChange={handleChange}
                    multiline
                  />
                </Box>
              </Grid>
              <Grid item xs={12} sm={6}></Grid>
            </Grid>
          </form>
        </DialogContent>
        <DialogActions>
          <SubmitButton
            onClick={handleSubmit}
            color="primary"
            variant="contained"
            size="small"
          >
            {t('COMMON007')}
          </SubmitButton>
          <CancelButton
            onClick={handleClose}
            color="primary"
            variant="contained"
            size="small"
          >
            {t('COMMON008')}
          </CancelButton>
        </DialogActions>
      </Dialog>
    </div>
  );
}
